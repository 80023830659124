<template>
    <ion-page>
        <app-header title="C-Suite Jobs"></app-header>
        <ion-content>
          <ion-fab vertical="bottom" horizontal="end" slot="fixed">
            <ion-fab-button @click="showJobEditor()" color="primary">
                <ion-icon name="create"></ion-icon>
            </ion-fab-button>
          </ion-fab>
            <ion-item v-for="(job,index) in jobs" :key="index" v-router-link="{name:'JobDetail', params:{id:job.id}}">
                <ion-thumbnail slot="start">
                    <img v-if="job.company" :src="job.company.picture">
                </ion-thumbnail>
                <ion-label>
                    <h2>{{job.name}}</h2>
                    <p>{{job.company && job.company.name}}</p>
                    <p>{{job.location}}</p>
                    <!-- {{job.descriptionText.substring(0, 150)}}... -->
                </ion-label>
            </ion-item>
            <p v-if="endOfPage"> No more jobs </p>
            <infinite-scroll v-else @fetchMore="loadMore" />
        </ion-content>
    </ion-page>
</template>

<script>
import AppHeader from '@/components/global/AppHeader';
import ListJobs from '@/gql/job/ListJobs.gql';
import InfiniteScroll from '@/components/global/InfiniteScroll';
import JobEditor from '@/components/modals/JobEditor.vue';

export default {
    components: {
        AppHeader,
        InfiniteScroll,
        JobEditor
    },
    apollo: {
        jobs: {
            query: ListJobs,
            variables () {
                return {
                    offset: 0
                }
            }
        }
    },
    data(){
        return{
            startPoint: 0,
            endOfPage: false,
            offset: 0
        }
    },
    methods:{
        async showJobEditor () {
            let modal = await this.$ionic.modalController.create({
                component: JobEditor,
                update: (cache, { data }) => {
                    this.updateJobListCache(cache, data);
                }
            })
            modal.onDidDismiss().then((data)=>{
                if(data.data) {
                    this.job = {...data.data};
                }
            });
            await modal.present()
        },
        updateJobListCache(cache, data) {
            let { jobs } = cache.readQuery({ query: listJobs })
            let index = jobs.map(job => job.id).indexOf(data.returning[0].id);
            if(index > 1) {
                jobs.splice(index, 1);
            }
            cache.writeQuery({
                query: listJobs,
                data: {
                    'jobs': jobs
                }
            });
        },
        async loadMore(event) {
            this.startPoint += 10
            this.$apollo.queries.jobs.fetchMore({
                variables:{
                    offset:this.startPoint
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    if (!fetchMoreResult.jobs.length) {
                        this.endOfPage = true
                        return previousResult;
                    }
                    else{
                        const newJobs = fetchMoreResult.jobs
                        return{
                            jobs:[...previousResult.jobs, ...newJobs]
                        }
                    }
                }
            })
            event.target.complete();
        }
    }
}
</script>

<style scoped>
</style>
