<template>
    <div>
        <ion-header>
        <ion-toolbar>
            <ion-title>Claim Perk</ion-title>
            <ion-icon @click="closeMe" slot="end" size="large" name="close" />
        </ion-toolbar>
        </ion-header>
        <ion-content v-if="claimType=='ENQUIRY'" class="ion-padding">
            Your name and email have been shared with    <b>{{companyName}}</b>, who will contact you shortly.
        </ion-content>
        <ion-content v-else class="ion-padding">
            <p v-if="claimType=='CODE'">
                To claim this perk, visit <b><i>{{claimUrl}}</i></b> and use the following code: <b>{{code}}</b>.
            </p>
            <p v-else>
                To claim this perk, visit <b><i>{{claimUrl}}</i></b>.
            </p>
            <div class="buttons">
                <ion-button v-if="claimType=='CODE'" expand="full" v-clipboard="() => code">Copy Code</ion-button>
                <ion-button expand="full" @click="closeMe" :href="claimUrl" target="_blank">Visit Website</ion-button>
            </div>
        </ion-content>
        
    </div>
</template>

<script>

export default {
    name: 'Modal',
    props: {
        closeMe: { type: Function, default: () => {} },
    },
    data() {
        return {
            content: 'Content',
        }
    }
}
</script>
<style  scoped>
    .buttons{
        margin-top:50px;
    }
</style>