import OT from "@opentok/client";
import $auth from "@/services/auth";
import {apolloClient} from "@/services/apollo";
import InsertToken from "@/gql/OpenTok/InsertToken.gql"
const openTokKey = "46690902";

function initSession(sessionId){    
    return OT.initSession(openTokKey, sessionId);
}

async function joinSession(session,role){
    var token = await getToken(session.sessionId,role);
    token= token.data.insert_OpenTokToken
    
    return new Promise(function(resolve,reject){
        session.connect(token,(err)=>{
            console.log("err", err);
            
            !!err ? reject(err) : resolve();
        });
    });
}
async function getToken(openTokSessionId,openTokRole){
    var token = await apolloClient.mutate({
        mutation: InsertToken,
        variables:{
            sessionId: openTokSessionId,
            role: openTokRole,
            user:$auth.user().id
        }
    });
    return token;
}

export default {
    initSession,
    getToken,
    joinSession,
}
