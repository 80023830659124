<template>
<ion-page>
	<app-header title="My Profile">
		<ion-button fill="clear" title="edit profile" @click="editProfile()">
			<ion-icon name="create" class="icon-create"></ion-icon>
		</ion-button>
	</app-header>
	<ion-content>
		<ion-spinner v-if="$apollo.queries.memberDetail.loading" name="bubbles" class="spinner"></ion-spinner>
		<div v-else>
			<ion-card>
				<ion-card-header>
					<div class="avatar" v-if="memberDetail.picture">
						<img :src="memberDetail.picture" alt="">
					</div>
					<div class="ion-text-center">
						<h3>{{ memberDetail.name }}</h3>
						<h5 class="country">
							{{ memberDetail.title }} <span v-if="memberDetail.companyName">at {{ memberDetail.companyName }}</span>
						</h5>
						<h5 class="country">{{ memberDetail.location }}</h5>
					</div>
				</ion-card-header>
				<ion-card-content class="card-content">
					<div class="social-media-links">
						<ion-button :href="memberDetail.linkedinUrl" rel="external" target="_blank" class="linkedIn">
							<ion-icon ios="ios-logo-linkedin" md="md-logo-linkedin"></ion-icon>
						</ion-button>
						<ion-button v-if="memberDetail.twitterAccountId" color="secondary" :href="`https://twitter.com/${memberDetail.twitterAccountId}`" rel="external" target="_blank">
							<ion-icon ios="ios-logo-twitter" md="md-logo-twitter"></ion-icon>
						</ion-button>
						<ion-button v-if="memberDetail.mediumAccountId" color="dark" :href="`https://medium.com/@${memberDetail.mediumAccountId}`" rel="external" target="_blank">
							<img src="https://img.icons8.com/ios-glyphs/30/ffffff/medium-monogram.png" />
						</ion-button>
					</div>
				</ion-card-content>
			</ion-card>

			<post-card v-for="post in memberDetail.posts" :key="post.id" :post="post"></post-card>
		</div>

	</ion-content>
</ion-page>
</template>

<script>
import $auth from '@/services/auth.js';
import $notifications from '@/services/notifications.js';
import GetMemberDetail from '@/gql/members/GetMemberDetail.gql';
import ProfileEditor from "@/components/me/ProfileEditor";
import AppHeader from '@/components/global/AppHeader';
import PostCard from '@/components/feed/PostCard';

export default {
	components: {
		AppHeader,
		PostCard,
	},

	apollo: {
		memberDetail: {
			query: GetMemberDetail,
			variables() {
				return {
					id: $auth.user().id,
				};
			}
		},
	},

	data() {
		return {
			memberDetail: null,
		};
	},

	methods: {
		async editProfile() {
			const modal = await this.$ionic.modalController.create({
				component: ProfileEditor,
				componentProps: {
					data: {
						memberDetail: JSON.parse(JSON.stringify(this.memberDetail))
					},
				},
			});

			modal.onDidDismiss().then((data) => {
				data.data && this.$apollo.queries.memberDetail.refetch()
			})
			await modal.present()
		},

		refetchConnection() {
			this.$apollo.queries.connection.refetch();
		},
	},
}
</script>

<style scoped>
.spinner {
	display: block;
	margin: 10px auto;
}

.avatar {
	max-width: 250px;
	margin: 0 auto;
}

.card-content {
	padding-top: 0;
	text-align: center;
}

.avatar img {
	background: #f1f1f1;
}

.social-media-links {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

.social-media-links ion-button.linkedIn {
	--background-activated: #0e76a8;
	--background: #0e76a8;
}

.social-media-links ion-button {
	margin-right: 10px;
}
</style>
