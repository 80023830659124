<template>
    <ion-page>
        <app-header title="Perk Detail">
            <ion-button v-if="isOwnedByCurrentUser" @click="showEditOptions">Edit</ion-button>
        </app-header>
        <ion-content>
            <ion-card v-if="perk">
                <ion-card-header>
                    <ion-card-title>{{perk.name}}</ion-card-title>
                    <ion-card-subtitle>-by {{perk.company.name}}</ion-card-subtitle>
                </ion-card-header>
                <ion-chip v-for="tag in perk.tags" :key="tag">
                    <ion-label color="secondary">{{tag}}</ion-label>
                </ion-chip>
                <ion-card-content>
                    {{perk.description}}
                </ion-card-content>
                <ion-button expand="full" @click="claimPerk">Claim</ion-button>
            </ion-card>
        </ion-content>
    </ion-page>
</template>

<script>
import AppHeader from '@/components/global/AppHeader';
import PerkEditor from '@/components/modals/PerkEditor';
import GetPerkDetail from '@/gql/perk/GetPerkDetail.gql';
import ListPerks from '@/gql/perk/ListPerks.gql';
import PerkClaim from '@/components/modals/PerkClaim';
import DeletePerk from '@/gql/perk/DeletePerk.gql';
import $auth from '@/services/auth';
import $utils from '@/services/utils';


export default {
    components: {
        AppHeader,
        PerkEditor
    },
    apollo: {
        perk: {
            query: GetPerkDetail,
            variables () {
                return {
                    id: this.$route.params.id
                }
            },
        }
    },
    computed: {
        isOwnedByCurrentUser() {
            return this.perk && this.perk.contactPersonId === $auth.user().id;
        }
    },
    mounted() {
        this.$on('close', () => {
            this.$ionic.modalController.dismiss()
        })
    },
    methods:{
        claimPerk(){
            return this.$ionic.modalController
            .create({
                component: PerkClaim,
                cssClass: 'my-custom-class',
                componentProps: {
                    data: {
                        claimUrl: this.perk.claimUrl,
                        code: this.perk.claimCode,
                        claimType: this.perk.claimType,
                        companyName: this.perk.company.name
                    },
                        propsData: {
                        closeMe: () => {
                            this.$ionic.modalController.dismiss()
                        },
                    },
                },
            })
            .then(m => m.present())
        },
        async deletePerk() {
            let proceedWithDelete = await $utils.confirm({
                header:'Delete perk',
                message: 'Are you sure you wish to delete this perk?'
            });
            if(!proceedWithDelete){
                return false;
            }
            let loadingController = await this.$ionic.loadingController.create({message:'Please wait...'});
            loadingController.present();

            this.$apollo.mutate({
                mutation: DeletePerk,
                variables:  {
                    perkId: this.perk.id
                },
                update: (cache, { data }) => {
                    this.updatePerkListCache(cache, data);
                }
            }).then(()=>{
                loadingController.dismiss(true);
                this.$router.push({
                    name: 'PerkList'
                });
            });
        },
        updatePerkListCache(cache, data) {
            let { perks } = cache.readQuery({ query: ListPerks })
            let index = perks.map(perk => perk.id).indexOf(data.deleteperk.returning[0].id);

            if(index > -1) {
                perks.splice(index, 1);
            }

            cache.writeQuery({
                query: ListPerks,
                data: {
                    'perks': perks
                }
            });

        },
        async initiatePerkEdit() {
            let initialPerk = { ...this.perk };
            initialPerk.tags = [ ...this.perk.tags ];

            let modal = await this.$ionic.modalController.create({
                component: PerkEditor,
                    componentProps: {
                        propsData: {
                            initialPerk: initialPerk
                    },
                }
            });
            modal.onDidDismiss().then((data)=>{
                if(data.data) {
                    this.perk = {...data.data};
                }
            });
            await modal.present();
        },
        showEditOptions() {
            return this.$ionic.actionSheetController
                .create({
                header: 'Edit Perk',
                buttons: [
                    {
                    text: 'Edit',
                    role: 'share',
                    icon: 'edit',
                        handler: () => {
                            this.initiatePerkEdit();
                        },
                    },
                    {
                    text: 'Delete',
                    icon: 'destructive',
                        handler: () => {
                            this.deletePerk();
                        },
                    },
                    {
                    text: 'Cancel',
                    icon: 'close',
                    role: 'cancel',
                        handler: () => {
                        },
                    },
                ],
            }).then(a => a.present())
        }
    }
}
</script>
