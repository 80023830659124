<template>
    <div>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button @click="dismiss()">
                        <ion-icon slot="icon-only" name="close"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title>Insight Editor</ion-title>
                <ion-buttons slot="end">
                    <ion-button @click="save()" :disabled="!canCreate || uploading">{{this.buttonText}}</ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
            <ion-list>
                <textarea-field placeholder="Write something..." v-model="insight.title" label="Title" ></textarea-field>
                <html-editor v-model="insight.content"></html-editor>
                <tag-input v-model="insight.tags" label="Enter Tags"></tag-input>
                <image-input v-model="insight.picture"></image-input>
                <p>{{ insight.publishedAt}}</p>
            </ion-list>
        </ion-content>
    </div>
</template>
<script>
import $media from '@/services/media';
import listInsights from '@/gql/insight/ListInsights.gql';
import GetInsightDetail from '@/gql/insight/GetInsightDetail.gql';
import InsertInsight from '@/gql/insight/InsertInsight.gql';
import UpdateInsight from '@/gql/insight/UpdateInsight.gql';
import TagInput from '@/components/inputs/TagInput';
import HtmlEditor from '@/components/inputs/HtmlEditor';
import ImageInput from '@/components/inputs/ImageInput.vue';
import TextareaField from '@/components/inputs/TextareaField.vue';
import $auth from '@/services/auth';
import {apolloClient} from '@/services/apollo';
import { v4 as uuidv4 } from 'uuid';

export default {
    name: 'insight-editor',
    components: {
        ImageInput,
        TagInput,
        HtmlEditor,
        TextareaField
    },
    data (){
        return{
            uploading: false,
            insightId:null,
            insight: {
                title: '',
                content:'',
                language:'',
                src: '',
                tags:[],
                publishedAt:'',
            },
            buttonText : ""
        };
    },
    props: {
        initialInsight: {
            type: Object,
            required: false
        },
        insightID : {
            type: String,
            required: false
        }
    },
    beforeMount() {
        this.insight = this.initialInsight ? this.initialInsight : this.insight;
        this.insightId = this.insightID ? this.insightID : null;
        this.buttonText = this.insightID ? "Update" : "Create";
    },
    computed: {
        canCreate() {
            return this.insight.picture;
        }
    },
    methods:{
        dismiss () {
            this.$ionic.modalController.dismiss(false);
        },
        save () {
            this.insightId ? this.updateInsight() : this.createInsight()
        },

        async createInsight () {
            let loadingController = await this.$ionic.loadingController.create({message:'Please wait...'});
            loadingController.present();

            await apolloClient.mutate({
                mutation: InsertInsight,
                    variables: {
                        id: uuidv4(),
                        authorId: $auth.user().id,
                        title: this.insight.title,
                        picture: this.insight.picture ,
                        content: this.insight.content,
                        tags: `{ ${this.insight.tags} }`,
                        publishedAt: new Date().toISOString(),
                        language:'eng'
                    },
                update: (cache, { data }) => {
                    this.updateInsightListCache(cache, data);
                }
            });
            loadingController.dismiss();
            this.dismiss(true);
        },
        async updateInsight () {
            let loadingController = await this.$ionic.loadingController.create({message:'Please wait...'});
            loadingController.present();

            await apolloClient.mutate({
                mutation: UpdateInsight,
                variables: {
                    id: this.insightId,
                    authorId:$auth.user().id,
                    title:this.insight.title,
                    picture: this.insight.picture,
                    content: this.insight.content,
                    tags:`{ ${this.insight.tags} }`,
                },
                update: (cache, { data }) => {
                    this.updateInsightByIdCache(cache, data);
                }
            });
            loadingController.dismiss();
            this.dismiss(this.insight);
        },
        updateInsightListCache(cache, data) {
            let  {insights } = cache.readQuery({
                query: listInsights,
            });
            insights.unshift(data.insert_Insight.returning[0]);
            cache.writeQuery({
                query: listInsights,
                data: {
                    'insights': insights
                }
            });
        },
        updateInsightByIdCache(cache, data) {
            let { insight } = cache.readQuery({ query: GetInsightDetail, variables: { id: this.insightId } });
            let insight_updated = data.update_Insight.returning[0];

            this.insight.title = insight_updated.title;
            this.insight.content = insight_updated.content;
            this.insight.picture = insight_updated.picture;
            this.insight.tags = insight_updated.tags;
            this.insight.publishedAt = insight_updated.publishedAt;

            cache.writeQuery({
                query: GetInsightDetail,
                variable: {id: insight_updated.id},
                data: {
                    'Insight_by_pk': insight_updated
                }
            });
        }
    }
}
</script>
