<template>
    <ion-page>
        <app-header title="C-Suite Introduction"></app-header>
        <ion-content>
            <ion-refresher slot="fixed" id="refresher" @ionRefresh="refresh($event)">
                <ion-refresher-content></ion-refresher-content>
            </ion-refresher>
            <ion-spinner v-if="$apollo.loading" name="bubbles" class="spinner"></ion-spinner>
            <div v-else-if="introductions && introductions.length < 1">
               <ion-label>
                   As a member of C-Suite Circle, you can request warm intros to anyone in the network. Use the Member Directory to request an intro now.
               </ion-label>
               <button v-router-link="{ name: 'MemberList'}">
                   Go to Member Directory
               </button>
           </div>
            <ion-list v-else>
                <ion-item v-for="introduction in introductions" :key="introduction.id">
                    <ion-thumbnail slot="start">
                        <img :src="introduction.targetPerson.picture">
                    </ion-thumbnail>
                    <ion-label>
                        <h2>{{ introduction.targetPerson.name }}</h2>
                        <h3>{{ introduction.targetPerson.title }} <span v-if="introduction.targetPerson.companyName">at {{  introduction.targetPerson.companyName }}</span></h3>
                        <p>{{ loadStatusText(introduction, introduction.createdAt , introduction.processedAt)}}</p>
                        <ion-text v-if="introduction.declineReason" color="danger">
                           {{ introduction.declineReason }}
                        </ion-text>
                    </ion-label>
                 </ion-item>
             </ion-list>
        </ion-content>
      </ion-page>
</template>
<script>
import ListIntroduction from '@/gql/introduction/ListIntroduction.gql';
import AppHeader from '@/components/global/AppHeader';
import $auth from "@/services/auth.js";

export default {
    components: {
        AppHeader,
    },
    apollo: {
        introductions: {
            query: ListIntroduction,
            variables() {
                return {
                    limit: this.perPage,
                    offset: 0,
                    personId: $auth.user().id,
                }
            },
        }
    },
    data() {
        return {
            introductions: [],
            offset: 0,
            page: 0,
            perPage: 12,
        };
      },
      methods: {
          async refresh(event) {
              this.page = 0;
              this.offset = 0;
              await this.$apollo.queries.introductions.refetch();
              event.target.complete();
          },
          async fetchMore(event) {
              this.page++;
              this.offset = this.page * this.perPage;
              await this.$apollo.queries.introductions.fetchMore({
                  variables: {
                      limit: this.perPage,
                      offset: this.offset,
                  },
                  updateQuery: (previousResult, { fetchMoreResult }) => {
                      if (!fetchMoreResult) {
                          return previousResult;
                      }
                      return Object.assign({}, previousResult, {
                          introductions: [ ...previousResult.introductions, ...fetchMoreResult.introductions]
                      });
                  },
              });
              event.target.complete();
          },
          loadStatusText(introduction, createdAt, processedAt){
            if (introduction.status == "REQUESTED") return "Requested at "+ this.$moment(createdAt).format('D/M/YYYY');
            if (introduction.status == "SENT") return "Sent at "+ this.$moment(processedAt).format('D/M/YYYY');
            if (introduction.status == "DECLINED") return "Declined at "+ this.$moment(processedAt).format('D/M/YYYY');
          }
      }
}
</script>
