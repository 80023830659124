<template>
    <div class="ion-padding">
        <ion-label position="stacked" class="editor-label">{{ label }}</ion-label>
        <quill-editor
            class="editor"
            :content="content"
            :options="editorOption"
            @change="onEditorChange($event)"
        />
    </div>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

import { quillEditor } from 'vue-quill-editor';

export default {
    name: 'html-editor',

    components: {
        quillEditor,
    },

    props: {
        label: {
            type: String,
            default: 'Content',
        },
        value: {
            type: String,
            default: '',
        },
    },

    data () {
        return {
            content: this.value,
            editorOption: {
                theme: 'snow',
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        [{ 'direction': 'rtl' }],
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        [{ 'color': [] }, { 'background': [] }],
                        [{ 'align': [] }],
                        ['link', 'image'],
                        ['code-block'],
                    ]
                },
            },
        };
    },

    methods: {
        onEditorChange({ quill, html, text }) {
            this.content = html;
            this.$emit('input', this.content);
        },
    },
};
</script>

<style scoped>
    .editor-label {
        display: inline-block;
        margin-bottom: 10px;
    }
</style>

