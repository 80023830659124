<template>
<div>
    <ion-tabs>

        <ion-tab tab="/tabs/home">
            <ion-vue-router name="home"></ion-vue-router>
        </ion-tab>

        <ion-tab tab="/tabs/events">
            <ion-vue-router name="events"></ion-vue-router>
        </ion-tab>

        <ion-tab tab="/tabs/conversations">
            <ion-vue-router name="conversations"></ion-vue-router>
        </ion-tab>

        <ion-tab tab="/tabs/notifications">
            <ion-vue-router name="notifications"></ion-vue-router>
        </ion-tab>

        <ion-tab tab="/tabs/more">
            <ion-vue-router name="more"></ion-vue-router>
        </ion-tab>

        <ion-tab-bar v-show="!hideTabs" slot="bottom">
            <ion-tab-button tab="/tabs/home">
                <ion-icon name="home"></ion-icon>
                <ion-label>Home</ion-label>
            </ion-tab-button>

            <ion-tab-button tab="/tabs/events">
                <ion-icon name="calendar" />
                <ion-label>Events</ion-label>
            </ion-tab-button>

            <ion-tab-button tab="/tabs/conversations">
                <ion-icon name="chatbox" />
                <ion-label>Inbox</ion-label>
            </ion-tab-button>

            <ion-tab-button tab="/tabs/notifications">
                <ion-icon name="notifications" />
                <ion-label>Notifications</ion-label>
                <ion-badge v-if="unseenCount > 0">{{unseenCount}}</ion-badge>
            </ion-tab-button>

            <ion-tab-button tab="/tabs/more">
                <ion-icon name="menu" />
                <ion-label>More</ion-label>
            </ion-tab-button>

        </ion-tab-bar>

    </ion-tabs>

</div>
</template>
<script>
import UnseenCount from '@/gql/tabs/GetUnseenCount.gql';
import $auth from "@/services/auth";

export default {
    apollo: {
        unseenCount: {
            query: UnseenCount,
            variables() {
                return {
                    recipientId: $auth.user().id
                }
            },
            watchQuery: {
                fetchPolicy: 'cache-and-network'
            },
            fetchPolicy: 'cache-and-network',
            result({
                data,
                loading
            }) {
                if (!loading) {
                    this.unseenCount = data.unseen.aggregate.count;
                }
            },
            manual: true,
        },
    },
    data() {
        return {
            unseenCount: 0,
            hideTabs: true,
        }
    },
    methods: {
        getUnseenCount() {
            this.$apollo.queries.unseenCount.refetch();
        },
        checkTabs() {
          const meta = this.$router.currentRoute.meta;
          this.hideTabs = meta.hideTabs;
        },
    },
    created() {
        this.$on("updateStatus", this.getUnseenCount);
        setInterval(this.getUnseenCount, 5 * 60 * 1000);//Refresh every 5 min
        this.checkTabs();
        this.$router.afterEach(this.checkTabs);
    },
}
</script>
