<template>
    <ion-card v-if="person">
        <b>{{heading}}</b>
        <img :src="person.picture" />
        <ion-card-title>{{person.name}}</ion-card-title>
        <ion-card-content>{{person.description}}</ion-card-content>
    </ion-card>
</template>

<script>
export default {
    props: {
        person: {
            type: Object,
            default: ''
        },
        heading: {
            type: String,
            default: 'Person Detail'
        }
    }
}
</script>

<style  scoped>
    .company-logo {
        margin-left: 20%;
        height: 200px;
        width: 200px;
    }
</style>