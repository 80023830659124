<template>
    <div id="app">
        <ion-app>
            <ion-vue-router></ion-vue-router>
        </ion-app>
    </div>
</template>

<script>
import $cordova from '@/services/cordova.js';
export default {
    created() {
        $cordova.init();
    }
}
</script>

<style>
#app {
    font-family: var(--csuite-font-family-sans-serif);
    font-size: 14px;
}

h1 {
    font-family: var(--csuite-font-family-sans-serif-bold);
    font-weight: 700;
    font-size: 24px;
    color: var(--csuite-label-text-color);
}

</style>
