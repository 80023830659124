import {apolloClient} from '@/services/apollo';
import InsertPushToken from '@/gql/login/PushToken.gql';
import $auth from '@/services/auth'

var pushToken = null;

function init(){
    console.log('Init Cordova');
    document.addEventListener('deviceready', function(){
        navigator && navigator.splashscreen && navigator.splashscreen.hide();
        if(window.PushNotification){
            let pushManager = PushNotification.init({
                android: {
                    "senderID": "956671511485",
                    "icon":"notification",
                    "iconColor":"blue"
                },
                ios: {
                    "alert": true,
                    "badge": true,
                    "sound": true,
                    "clearBadge": true
                }
           });
           pushManager.on("registration", function(data) {
               console.log("Registered", data);
               savePushToken(data.registrationId);
           });
           pushManager.on("notification", function(notification){
               console.log("Notification", notification);
               // var payload = notification.additionalData;
               // if(!payload.foreground && payload.path){
               //   window.location.href = "index.html#" + payload.path;
               // }
           })
         }
         else{
             console.log("no Push Notification Service");
         }

    });
}

async function savePushToken(token){
    if ($auth.user()){
        await apolloClient.mutate({
            mutation:InsertPushToken,
            variables:{
                personId: $auth.user().id,
                platform: cordova.platformId,
                token: token
            }
        });
    }
}

export default {
    init,
    savePushToken
}
