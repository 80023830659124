<template>
    <div>
        <ion-button v-if="introductionStatus == 'REQUESTED'" color="warning" disabled>
            Introduction Pending
        </ion-button>
        <ion-button v-else-if="introductionStatus == 'SENT'" color="success" disabled>
            Introduction Sent
        </ion-button>
        <ion-button v-else-if="introductionStatus == 'DECLINED'" color="danger" disabled>
            Introduction Declined
        </ion-button>
        <ion-button v-else @click="requestIntroduction" color="success">
            Request Introduction
        </ion-button>
    </div>
</template>

<script>
import $auth from '@/services/auth.js';
import RequestIntroduction from '@/gql/introduction/RequestIntroduction.gql';
import GetRequestIntroduction from '@/gql/introduction/GetRequestIntroduction.gql';
import UpdateIntroCredit from '@/gql/members/UpdateIntroCredit.gql';

import IntroductionRequestModal from '@/components/introduction/IntroductionRequestModal.vue';
import connectionMixin from '@/mixins/connectionMixin';

export default {
    props: {
        memberDetail: {
            type: Object,
            required: true,
        },
    },

    apollo: {
        introduction: {
            query: GetRequestIntroduction,
            variables() {
                return {
                    personId: $auth.user().id,
                    targetId: this.$route.params.id
                };
            },
        },
    },

    data() {
        return {
            introduction : null,
            user: $auth.user(),
        };
    },

    mixins: [ connectionMixin ],

    computed: {
        introductionStatus() {
            return this.introduction && this.introduction[0] && this.introduction[0].status;
        },
    },

    methods: {
        async requestIntroduction() {
            if (this.isConnected || this.user.introCredits > 0) {
                const modalController = await this.$ionic.modalController.create({
                    component: IntroductionRequestModal,
                    componentProps: {
                        parent: this,
                        data: { targetName: this.memberDetail.name },
                    }
                });
                
                modalController.onDidDismiss().then(({ data })=>{
                    if(data && data.send) this.sendIntroRequest(data.requestReason);
                });

                await modalController.present();

            } else {
                await this.$ionic.alertController.create({
                    header: 'Insufficient credits',
                    buttons: [{ text: 'Cancel', role: 'cancel' }]
                }).then(a => a.present());
            }
        },

        async sendIntroRequest(reason) {
            let loadingController = await this.$ionic.loadingController.create({message:'Please wait...'});
            loadingController.present();

            await this.$apollo.mutate({
                mutation: RequestIntroduction,
                variables: {
                    personId: this.user.id,
                    targetId: this.memberDetail.id,
                    requestReason: reason,
                },
                update: async (cache, { data }) => {
                    this.introduction.push(data.insert_Introduction.returning[0]);
                },
            }).then(() => {
                if(!this.isConnected) this.updateIntroCredit();
            });

            loadingController.dismiss();
        },

        async updateIntroCredit() {
            await this.$apollo.mutate({
                mutation: UpdateIntroCredit,
                variables: {
                    id: this.user.id,
                    introCredits: this.user.introCredits - 1,
                },
                update: async (client, { data: { update_Person } }) => {
                    this.user = update_Person.returning[0];
                },
            }).catch(err => console.log(err));
        },
    },
};
</script>