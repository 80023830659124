<template>
    <ion-page class='detail-page'>
        <app-header title="Job Detail" >
            <ion-button @click="showEditor()">Edit</ion-button>
        </app-header>
        <ion-content>
        <ion-spinner v-if="$apollo.loading"></ion-spinner>
        <ion-card v-if="jobDetail" class="ion-padding">
            <ion-card-header>
                <img class="company-logo" v-if="jobDetail.company" :src="jobDetail.company.picture" alt="">
                <h1>{{jobDetail.name}}</h1>
                <ion-card-subtitle v-if="jobDetail.company" >{{jobDetail.company.name}}</ion-card-subtitle>
                <p class="location"><i class="icons8-marker"></i>{{jobDetail.location}}</p>
            </ion-card-header>
            <ion-card-content>

                <div class="section description">
                    <h2>Description</h2>
                    <p :class="{'description-text': true, 'see-less': showLess }" v-html="jobDetail.descriptionText"></p>
                    <span @click="showLess = !showLess" class="read-more">{{ showLess ? 'Read more' : 'Read less'}}</span>
                </div>
                <action-bar :article="jobDetail"></action-bar>
                <div class="section skills" v-if="jobDetail.jobFunction">
                    <h1>Necessary Skills</h1>
                    <div v-for="(skill,index) in jobDetail.jobFunction" :key="index">{{skill}}</div>
                </div>

                <div class="section industries"  v-if="jobDetail.industries">
                    <h1>Industries</h1>
                    <div v-for="(industry,index) in jobDetail.industries" :key="index">{{industry}}</div>
                </div>
            </ion-card-content>
        </ion-card>
        </ion-content>
        <ion-footer v-if="showBanner && jobDetail && jobDetail.applicationLink" slot="bottom">
            <cs-button type="primary" :href="jobDetail.applicationLink">Send Application</cs-button>
        </ion-footer>
    </ion-page>
</template>

<script>
import AppHeader from '@/components/global/AppHeader';
import ActionBar from '@/components/global/ActionBar';
import listJobs from '@/gql/job/ListJobs.gql';
import getJobDetail from '@/gql/job/GetJobDetail.gql';
import DeleteJob from '@/gql/job/DeleteJob.gql';
import JobEditor from '@/components/modals/JobEditor';
import $auth from '@/services/auth';
import LinkPreview from '@/components/feed/LinkPreview';

export default {
    components: {
        AppHeader,
        JobEditor,
        LinkPreview,
        ActionBar
    },
    apollo: {
        jobDetail: {
            query: getJobDetail,
            variables () {
                return {
                    id: this.$route.params.id,
                    currentUserId: this.user.id
                };
            }
        }
    },
    data() {
        return {
            user: $auth.user(),
            showLess: true,
            showBanner: true
        }
    },
    computed : {
        editable () {
            this.applicationLink = this.jobDetail.applicationLink;
            this.linkDescription = this.jobDetail.linkDescription;
            this.linkTitle = this.jobDetail.linkTitle;
            this.linkImage = this.jobDetail.linkImage;
        }
    },
    methods: {
        async showEditor () {
            let actionSheet = await this.$ionic.actionSheetController.create({
                header: 'Edit  Job',
                buttons: [
                    {
                        text: 'Edit',
                        handler: () => {
                            this.editJob()
                        }
                    },
                    {
                        text: 'Delete',
                        role: 'destructive',
                        handler: () => {
                            this.deleteJob()
                        },
                    },
                ]
            })
            actionSheet.present()
        },
        async editJob() {
            let modal = await this.$ionic.modalController.create({
                component: JobEditor,
                componentProps: {
                    data: {
                        jobId: this.jobDetail.id,
                        name: this.jobDetail.name,
                        descriptionText: this.jobDetail.descriptionText,
                        applicationLink:this.jobDetail.applicationLink,
                        linkTitle :this.jobDetail.linkTitle,
                        linkImage : this.jobDetail.linkImage,
                    },
                },
                update: (cache, { data }) => {
                    this.updateJobListCache(cache, data);
                }
            })
            modal.onDidDismiss().then((data)=>{
                if(data.data) {
                    this.job = {...data.data};
                }
            });
            await modal.present()
        },
        updateJobListCache(cache, data) {
            let { jobs } = cache.readQuery({ query: listJobs })
            let index = jobs.map(job => job.id).indexOf(data.showEditor.returning[0].id);

            if(index > 1) {
                jobs.splice(index, 1);
            }
            cache.writeQuery({
                query: listJobs,
                data: {
                    'jobs': jobs
                }
            });
        },
        async deleteJob () {
            if(confirm('Are you sure you wish to delete this post?')){
                let loadingController = await this.$ionic.loadingController.create({message:'Please wait...'});
                loadingController.present();
                await this.$apollo.mutate({
                    mutation: DeleteJob,
                    variables: {
                        id: this.$route.params.id
                    }
                })
                loadingController.dismiss()
                this.$router.go(-1)
            }
        }
    },
    beforeDestroy() {
        this.showBanner = false;
    }
}
</script>
<style scoped>

.company-logo {
    height: 90px;
    width: auto;
    margin: 0 auto;
}

ion-card {
    margin:0;
    background-color: var(--csuite-light);
}

ion-card-content, ion-card-header, .section {
    margin: 0 -16px;
    padding: 0 16px;
}

ion-card-header {
    border-bottom: 2px solid var(--csuite-default);
    background-color: white;
    text-align: center;
}

ion-card-header h1 {
    margin-top: 14px;
}

ion-card-subtitle {
    color: var(--csuite-subtitle-color);
    text-transform: capitalize;
    font-size: 14px;
    font-family: var(--csuite-font-family-sans-serif-bold);
}

ion-footer {
    height:75px;
    background-color: var(--csuite-light);
    text-align: center;
    padding: 15px;
    box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.09);
}

ion-footer > * {
    display: inline-block;
}

h1 {
    margin-bottom: 12px;
    font-size: 20px;
}

h2 {
    font-family: var(--csuite-font-family-sans-serif-bold);
    font-size: 14px;
    font-weight: 700;
    color: var(--csuite-section-title-color);
    text-transform: uppercase;
    margin-bottom: 18px;
}

p.location {
    color: var(--csuite-gray);
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 14px;
}

.section {
    background-color: white;
    padding-top: 22px;
    padding-bottom: 22px;
}

.section:not(.description) {
    margin-top: 8px;
    box-shadow: var(--csuite-box-shadow);
}

.section.skills > div,
.section.industries > div {
    display: inline-block;
    border-radius: 3px;
    background-color: var(--csuite-gray);
    padding: 5px 8px;
    font-size: 12px;
    color: white;
    font-family: var(--csuite-font-family-sans-serif-bold);
    margin-right: 8px;
    margin-bottom: 8px;
    text-transform: uppercase;
}

.description {
    font-size: 14px;
}

.description .description-text.see-less {
    height: 40px;
    overflow-X: hidden;
}

.description span.read-more {
    color: var(--csuite-primary);
    font-family: var(--csuite-font-family-sans-serif-bold);
}
</style>
