<template>
    <form @submit.prevent="addComment">
        <ion-grid class="ion-no-padding">
            <ion-row class="ion-justify-content-start ion-align-items-center">
                <ion-col size="1.5" size-sm="1">
                    <ion-thumbnail v-if="user">
                        <img :src="user.picture">
                    </ion-thumbnail>
                </ion-col>
                <ion-col size="10.5"  size-sm="11">
                    <textarea-field
                        type="text"
                        v-model="content"
                        placeholder="Write a comment..."
                        required
                    />
                </ion-col>
                <ion-col size="12" class="ion-text-end">
                    <ion-button type="submit" :disabled="addBtnDisabled">Send</ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </form>
</template>

<script>
import TextareaField from '@/components/inputs/TextareaField';

export default {
    name: 'comment-composer',

    components: {
        TextareaField,
    },

    props: {
        user: {
            type: Object,
            default: () => {},
        },
    },

    computed: {
        addBtnDisabled() {
            return this.content.trim().length < 1;
        }
    },

    data() {
        return {
            content: '',
        }
    },

    methods: {
        addComment() {
            this.$emit('addComment', this.content);
            this.content = '';
        },
    }
}
</script>

<style scoped>
    ion-thumbnail {
        --border-radius: 50%;
        --size: 35px;
    }
</style>
