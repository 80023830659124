<template>
    <ion-list>
        <ion-item button @click.prevent="$ionic.popoverController.dismiss({ edit: true })">Edit comment</ion-item>
        <ion-item lines="none" @click.prevent="$ionic.popoverController.dismiss({ delete: true })" button>Delete comment</ion-item>
    </ion-list>
</template>

<script>
export default {
    name: 'comment-popover',
}
</script>

<style scoped>
    ion-list {
        margin-bottom: 0;
    }
</style>