import {AlertController} from '@ionic/vue'

function confirm(options){
    return new Promise(function(resolve, reject){
        const alert = document.createElement('ion-alert');
        alert.header = options.header;
        alert.message = options.message;
        alert.buttons = [
            {
                text: options.cancelText || 'Cancel',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => resolve(false)
            },
            {
                text: options.okText || 'OK',
                handler: () => resolve(true)
            }
        ];
        document.body.appendChild(alert);
        alert.present();
    });
}

export default {
    confirm
}
