import {apolloClient} from '@/services/apollo';
import $auth from "@/services/auth";
import SendNotification from '@/gql/notifications/SendNotification.gql';

function send({recipientId, targetId, typeId, itemType, itemId}) {
    apolloClient.mutate({
        mutation: SendNotification,
        variables: {
            recipientId: recipientId,
            actorId: $auth.user().id,
            targetId: targetId,
            typeId: typeId,
            itemType: itemType,
            itemId: itemId,
        }
    });
}

export default {
    send: send,
}
