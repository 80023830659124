<template>
    <ion-header>
        <ion-toolbar color="primary">
            <ion-buttons slot="start">
                <ion-back-button></ion-back-button>
            </ion-buttons>
            <ion-title>{{ title }}</ion-title>
            <ion-buttons slot="end">
                <slot></slot>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
</template>

<script>
export default {
    name: 'app-header',

    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>
