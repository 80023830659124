<template>
    <ion-page>
        <app-header title="Events" />
        <ion-content>
            <ion-fab vertical="bottom" horizontal="end" slot="fixed">
                <ion-fab-button @click="showEventEditor()" color="primary">
                    <!-- TODO Why doesn't the add ion icon show up? -->
                    <!-- <ion-icon name="add"></ion-icon> -->
                    <span class="add-icon">+</span>
                </ion-fab-button>
            </ion-fab>
            <ion-spinner v-if="loading"></ion-spinner>
            <ion-card v-for="event in events" :key="event.id" v-router-link="{name:'EventDetail', params:{id:event.id}}">
                <img :src="event.picture" alt="">
                <ion-card-header>
                    <ion-card-subtitle>{{event.start | moment('YYYY-MM-DD')}}</ion-card-subtitle>
                    <ion-card-title>{{event.name}}</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                    <p v-if="event.responses.length">Your status: {{event.responses[0].status}}</p>
                    <p v-else>You haven't respond to this event </p>
                    {{event.responses_aggregate.aggregate.count}} attending
                </ion-card-content>
            </ion-card>
            <p v-if="endOfPage">No more Events</p>
            <infinite-scroll v-else @fetchMore="fetchMore" />
        </ion-content>
    </ion-page>
</template>

<script>
import listEvents from '@/gql/event/ListEvents.gql';
import AppHeader from '@/components/global/AppHeader';
import InfiniteScroll from '@/components/global/InfiniteScroll';
import EventEditor from '@/components/event/EventEditor';
import $auth from '@/services/auth.js';

const PER_PAGE = 12;
export default {
    components: {
        AppHeader,
        InfiniteScroll
    },
    apollo: {
        events: {
            query: listEvents,
            variables() {
                return {
                    limit: PER_PAGE,
                    offset: 0,
                    where: {},
                    personId: $auth.user().id
                }
            },
            loadingKey: 'loading',
            watchQuery: {
                fetchPolicy: 'network-only'
            },
            fetchPolicy: 'network-only',
        }
    },
    data () {
        return {
            loading: null,
            endOfPage: false,
            offset: 0,
            page: 0
        }
    },
    methods:{
        async showEventEditor() {
            const modal = await this.$ionic.modalController.create({
                component: EventEditor,
            })
            modal.onDidDismiss().then((res)=>{
                if (res.data) {
                    this.$apollo.queries.events.refetch();
                    this.$router.push({name: 'EventDetail', params: {id: res.data}});
                }
            })
            await modal.present();
        },
        async fetchMore(event) {
            this.page++;
            this.offset = this.page * PER_PAGE;
            await this.$apollo.queries.events.fetchMore({
                variables: {
                    limit: PER_PAGE,
                    offset: this.offset,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    if (!fetchMoreResult) {
                        this.endOfPage = true
                        return previousResult;
                    }
                    return Object.assign({}, previousResult, {
                        events: [ ...previousResult.events, ...fetchMoreResult.events]
                    })
                },
            });
            event.target.complete();
        }
    }
}
</script>

<style scoped>
.add-icon {
    font-size: 36px;
    margin-top: -8px;
}
</style>
