import {apolloClient} from '@/services/apollo';
import InsertMediaItem from '@/gql/media/InsertMediaItem.gql';

const types = {
    'image':'image/*',
    'video':'video/*',
    'audio':'audio/*',
    'document':'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf'
}

function selectFile (type) {
    return new Promise(function(resolve,reject){
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = types[type];
        fileInput.addEventListener('change', (e) => {
            resolve(fileInput.files[0]);
        });
        fileInput.click();
    })
}

function doUpload (file, url, fileId) {
    return new Promise(function(resolve,reject){
        const xhr = new XMLHttpRequest();
        xhr.open("PUT", url);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    const url = "https://csg-cdn.s3.amazonaws.com/" + fileId;
                    resolve(url);
                }
            }
        };
        xhr.send(file);
    })
}

async function getUploadInfo(type){
    let response = await apolloClient.mutate({
        mutation: InsertMediaItem,
        variables: {
            type:type
        }
    });
    return response.data.insert_MediaItem;
}

async function uploadFile (file) {
    const uploadInfo = await getUploadInfo(file.type);
    const url = await doUpload(file, uploadInfo.url, uploadInfo.id);
    return {src: url, contentType: file.type, name: file.name};
}

export default {
    selectFile,
    uploadFile
}
