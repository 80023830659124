<template>
    <ion-item>
        <ion-thumbnail slot="start">
            <img :src="connection.toPerson.picture">
        </ion-thumbnail>
        <ion-label>
            <div v-router-link="{ name: 'MemberDetail', params: { id: connection.toPerson.id }}" class="member-detail">
                <h2>{{ connection.toPerson.name }}</h2>
                <h3>{{ connection.toPerson.title }} <span v-if="connection.toPerson.companyName">at {{  connection.toPerson.companyName }}</span></h3>
            </div>
            <div v-if="connection.status ==='PENDING'">
                <ion-button  color="primary" @click.prevent="approveRequest">
                    Approve
                </ion-button>
                <ion-button color="danger" @click.prevent="declineRequest" fill="outline">
                    Decline
                </ion-button>
            </div>
            <div v-else>
                <ion-text color="medium">
                    <p>{{ connection.status }}</p>
                </ion-text>
            </div>
        </ion-label>
    </ion-item>
</template>

<script>
import UpdateConnection from '@/gql/connection/UpdateConnection.gql';

export default {
    name: 'connection-card',

    props: {
        connection: {
            type: Object,
            default: () => {},
        },
    },

    methods: {
        async approveRequest() {
            await this.$apollo.mutate({
                mutation: UpdateConnection,
                variables: {
                    fromPersonId: this.connection.fromPerson.id,
                    toPersonId: this.connection.toPerson.id,
                    status: "APPROVED",
                }
            }).then(() => {
                this.$emit('refetchConnections');
                this.$emit('refetchPendingConnections');
            });
        },

        async declineRequest() {
            await this.$apollo.mutate({
                mutation: UpdateConnection,
                variables: {
                    fromPersonId: this.connection.fromPerson.id,
                    toPersonId: this.connection.toPerson.id,
                    status: "DECLINED",
                }
            }).then(() => {
                this.$emit('refetchPendingConnections');
            });
        },
    }
}
</script>

<style scoped>
    ion-thumbnail {
        --border-radius: 50%;
    }
    .member-detail {
        margin-bottom: 7px;
    }
</style>