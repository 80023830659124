<template>
    <ion-page>
        <app-header title="Menu"  v-router-link="{name:'DevTools'}"/>
        <ion-content>
            <ion-list>
                <ion-item v-router-link="{name:'Me'}">
                    <ion-icon color="secondary" name="person" slot="start"></ion-icon>
                    <ion-label>My Profile</ion-label>
                </ion-item>
                <ion-item v-router-link="{name:'InsightList'}">
                    <ion-icon color="secondary" name="newspaper" slot="start"></ion-icon>
                    <ion-label>Insights</ion-label>
                </ion-item>
                <ion-item v-router-link="{name:'JobList'}">
                    <ion-icon color="secondary" name="briefcase" slot="start"></ion-icon>
                    <ion-label>Jobs</ion-label>
                </ion-item>
                <ion-item v-router-link="{name:'MemberList'}">
                    <ion-icon color="secondary" name="people" slot="start"></ion-icon>
                    <ion-label>Member List</ion-label>
                </ion-item>
                <ion-item v-router-link="{name:'PerkList'}">
                    <ion-icon color="secondary" name="glasses" slot="start"></ion-icon>
                    <ion-label>Perks</ion-label>
                </ion-item>
                <ion-item v-router-link="{name:'IntroductionList'}">
                    <ion-icon color="secondary" name="globe" slot="start"></ion-icon>
                    <ion-label>Introductions</ion-label>
                </ion-item>
                <ion-item v-router-link="{name:'AdvisorList'}">
                    <ion-icon color="secondary" name="flask" slot="start"></ion-icon>
                    <ion-label>Advisors</ion-label>
                </ion-item>
                <ion-item @click="logout">
                    <ion-icon color="secondary" name="log-out" slot="start"></ion-icon>
                    <ion-label>Logout</ion-label>
                </ion-item>
            </ion-list>
        </ion-content>
    </ion-page>
</template>

<script>
import AppHeader from '@/components/global/AppHeader';
import $auth from '@/services/auth';

export default {
    components: {
        AppHeader
    },

    methods: {
        logout () {
            $auth.logout()
        }
    }
}
</script>
