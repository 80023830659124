<template>
    <ion-item>
        <ion-label position="stacked">{{ label }}</ion-label>
        <ion-datetime 
            :value="date" 
            @ionChange="setInput($event.target.value)"
            display-format="MMM DD, YYYY"
            display-timezone="utc"
        ></ion-datetime>
    </ion-item>
</template>

<script>
export default {
    name: 'date-input',

    props: {
        label: {
            type: String,
            default: 'Date',
        },
        value: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            date: this.value,
        };
    },

    methods: {
        setInput(val) {
            this.date = val;
            this.$emit('input', this.date);
        }
    },
}
</script>