import GetConnectionStatus from '@/gql/connection/GetConnectionStatus.gql';
import $auth from '@/services/auth.js';

export default {
    apollo: {
        connection: {
            query: GetConnectionStatus,
            variables() {
                return {
                    fromPersonId: $auth.user().id,
                    toPersonId: this.$route.params.id || this.$route.query.user
                };
            },
        },
    },

    data() {
        return {
            connection: null,
            user: $auth.user(),
        };
    },

    computed: {
        connectionStatus() {
            return this.connection && this.connection[0] && this.connection[0].status;
        },

        isConnected() {
            return Boolean(this.connection && this.connection[0] && this.connection[0].status == 'APPROVED');
        },

        hasMessageCredits() {
            return this.user.messageCredits > 0;
        },
    },
};