
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"MemberFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Person"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"title"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"picture"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"email"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"linkedinUrl"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"twitterAccountId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"companyId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"companyName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"cityId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"company"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"mediumAccountId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"location"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":268}};
    doc.loc.source = {"body":"fragment MemberFragment on Person {\n    id\n    name\n    title\n    picture\n#    twitter\n#    linkedin\n    email\n    linkedinUrl\n    twitterAccountId\n    companyId\n    companyName\n    cityId\n    company {\n        id\n        name\n    }\n    mediumAccountId\n    location\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
