<template>
    <div>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button @click="dismiss()">
                        <ion-icon slot="icon-only" name="close"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title>Job Edit</ion-title>
                <ion-buttons slot="end">
                    <ion-button @click="save()" :disabled="!canCreate || uploading">Create</ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
            <ion-list>
                <textarea-field placeholder="Write something..." v-model="name" label="Name"></textarea-field>
                <html-editor v-model="descriptionText" label="Description"></html-editor>
                <ion-item>
                    <ion-label position="stacked">Application Link</ion-label>
                    <ion-bottoms slot="end">
                        <ion-button @click="attachLink('link')" color="light">
                            <ion-icon slot="icon-only" name="globe"></ion-icon>
                        </ion-button>
                    </ion-bottoms>
                </ion-item>
                <div v-if="uploading" class="ion-padding ion-text-center">
                    <ion-spinner></ion-spinner>
                </div>
                <div v-if="!uploading && applicationLink">
                    <ion-button color="danger" @click="removeAttachment">
                        <ion-icon slot="icon-only" name="close"></ion-icon>
                    </ion-button>
                    <div @click.stop.capture>
                        <link-preview v-model="applicationLink" :title="linkTitle" :link="applicationLink" :description="linkDescription" :image="linkImage"></link-preview>
                    </div>
                </div>
            </ion-list>
        </ion-content>
    </div>
</template>
<script>
import listJobs from '@/gql/job/ListJobs.gql';
import getJobDetail from '@/gql/job/GetJobDetail.gql';
import InsertJob from '@/gql/job/InsertJob.gql';
import UpdateJob from '@/gql/job/UpdateJob.gql';
import getLinkPreview from '@/gql/feed/GetLinkPreview.gql';
import $auth from '@/services/auth';
import {apolloClient} from '@/services/apollo';
import LinkPreview from '@/components/feed/LinkPreview.vue';
import TextareaField from '@/components/inputs/TextareaField.vue';
import HtmlEditor from '@/components/inputs/HtmlEditor';
import { v4 as uuidv4 } from 'uuid';

export default{
    name: 'JobEditor',
    components: {
        LinkPreview,
        TextareaField,
        HtmlEditor
    },
    data(){
        return{
            jobId: null,
            previewLink: null,
            uploading:false,
            name: '',
            descriptionText:'',
            applicationLink:'',
            csuite:''
        };
    },
    beforeMount() {
        this.jobId = this.jobId ? this.jobId : "";
        this.name = this.name;
        this.applicationLink = this.applicationLink;
        this.linkDescription = this.descriptionText;
        this.linkTitle = this.linkTitle;
        this.linkImage = this.linkImage;
    },
    computed: {
        canCreate() {
            return this.name;
        }
    },
    methods:{
        dismiss () {
            this.$ionic.modalController.dismiss(false);
        },
        save () {
            this.jobId ? this.updateJob() : this.publish()
        },
        async publish () {
            let loadingController = await this.$ionic.loadingController.create({message:'Please wait...'});
            loadingController.present();

            await apolloClient.mutate({
                mutation: InsertJob,
                variables: {
                    id: uuidv4(),
                    contactPersonId: $auth.user().id,
                    name: this.name,
                    applicationLink:this.applicationLink,
                    descriptionText:this.descriptionText,
                    csuite:'true'
                },
                update: (cache, { data }) => {
                    this.updateJobListCache(cache, data);
                }
            });
            loadingController.dismiss();
            this.dismiss(true);
        },
        async updateJob () {
            let loadingController = await this.$ionic.loadingController.create({message:'Please wait...'});
            loadingController.present();

            await apolloClient.mutate({
                mutation: UpdateJob,
                variables: {
                    id: this.jobId,
                    contactPersonId: $auth.user().id,
                    name: this.name,
                    applicationLink:this.applicationLink,
                    descriptionText:this.descriptionText,
                },
                update: (cache, { data }) => {
                    this.updateJobByIdCache(cache, data);
                }
            });
            loadingController.dismiss();
            this.dismiss(true);
        },
        updateJobListCache(cache, data) {
            let { jobs } = cache.readQuery({ query: listJobs })

            jobs.push(data.insert_Job.returning[0]);

            cache.writeQuery({
                query: listJobs,
                data: {
                    'jobs': jobs
                }
            });
        },
        updateJobByIdCache(cache, data) {
            let job_updated = data.update_Job.returning[0];
            cache.writeQuery({
                query: getJobDetail,
                variable: {id: job_updated.id},
                data: {
                    'Job_by_pk': job_updated
                }
            });
        },
        async attachLink () {
            const linkModal = await this.$ionic.alertController.create({
                header: "Attach Link",
                inputs: [{
                    placeholder: 'https://www.csuitecircle.com/',
                    type: 'url',
                }],
                backdropDismiss: false,
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                    },
                    {
                        text: 'Ok',
                        handler: (d) => {
                            const link = this.validateLink(d[0]);
                            if (link) this.linkPreview(link);
                        },
                    },
                ],
            });
            linkModal.present();
        },
        validateLink (link) {
            try {
                const url = new URL(link);
                return url.href;
            } catch (e) {
                return null;
            }
        },
        async linkPreview (link) {
            this.attachmentType = "link";
            this.uploading = true;
            const previewQuery = await apolloClient.query({
                query: getLinkPreview,
                variables: {
                    url: link,
                }
            });
            this.uploading = false;

            const preview = previewQuery.data.LinkPreview;
            this.attach({
                type: "link",
                src: preview.image,
                name: preview.title,
                link: preview.link,
                description: preview.description
            });

            this.applicationLink = preview.link;
            this.linkDescription = preview.description;
            this.linkTitle = preview.title;
            this.linkImage = preview.image;
        },
        attach (attachment) {
            const props = ['type', 'src', 'name', 'link', 'description']
            props.forEach(p => {
                this['attachment' + p[0].toUpperCase() + p.slice(1,p.length)] = attachment[p] || null;
            });
        },
        removeAttachment () {
            this.applicationLink = '';
            this.linkDescription = '';
            this.linkTitle = '';
            this.linkImage = '';
        },
    }
}
</script>
