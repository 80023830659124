<template>
<ion-page>
	<ion-content class="ion-padding ion-text-center">
		<h2>Enable Notifications</h2>
		<p>Get notified when potential investors, customers, mentors communicate with you through the app.</p>
		<ion-button expand="block" @click="enable()">Enable Notifications</ion-button>
		<ion-button @click="skip()" fill="clear" color="medium">Maybe Later</ion-button>
	</ion-content>
</ion-page>
</template>

<script>
export default {
	methods: {
		enable() {
			alert("Thanks");
			this.skip();
		},
		skip() {
			this.$router.replace({
				name: "Home"
			});
		},
	}
}
</script>

<style scoped>
ion-content h2 {
	padding-top: 30%;
}
</style>
