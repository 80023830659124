<template>
    <ion-item>
        <ion-avatar slot="start">
            <img :src="member.picture">
        </ion-avatar>
        <ion-label>
            <h2>{{ member.name }}</h2>
            <h3>{{ member.title }} <span v-if="member.companyName">at {{  member.companyName }}</span></h3>
        </ion-label>
    </ion-item>
</template>

<script>
export default {
    name: 'member-card',

    props: {
        member: {
            type: Object,
            default() {
                return {};
            }
        },
    },
};
</script>

<style scoped>
    img {
        background: #f1f1f1;
    }
</style>