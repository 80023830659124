<template>
    <ion-page>
        <app-header title="C-Suite Insights"></app-header>
        <ion-content>
            <ion-refresher slot="fixed" id="refresher" @ionRefresh="refresh($event)">
                <ion-refresher-content></ion-refresher-content>
            </ion-refresher>
            <ion-fab vertical="bottom" horizontal="end" slot="fixed" ion-padding>
              <ion-fab-button @click="add()" color="primary">
                  <ion-icon name="create"></ion-icon>
              </ion-fab-button>
            </ion-fab>
            <ion-card v-for="insight in insights" :key="insight.id" v-router-link="{name:'InsightDetail', params:{id:insight.id}}">
                <img class="image" :src="insight.picture" alt="">
                <tag-list :tags="insight.tags.slice(0, 3)"></tag-list>
                <ion-card-header>
                    <ion-card-title>{{insight.title}}</ion-card-title>
                </ion-card-header>
                <ion-card-content v-if="insight.author">
                    <strong>{{ insight.author.name }}</strong>, {{insight.author.title}} at {{insight.author.companyName}}
                    <p>{{ insight.publishedAt | moment('MMM DD')}} - {{ insight.duration }}</p>
                </ion-card-content>
            </ion-card>
            <infinite-scroll @fetchMore="fetchMore" />
        </ion-content>
    </ion-page>
</template>

<script>
import listInsights from '@/gql/insight/ListInsights.gql';
import AppHeader from '@/components/global/AppHeader';
import InfiniteScroll from '@/components/global/InfiniteScroll';
import TagList from '@/components/global/TagList';
import InsightEditor from '@/components/modals/InsightEditor';

export default {
    components: {
        AppHeader,
        InfiniteScroll,
        TagList,
        InsightEditor
    },
    apollo: {
        insights: {
            query: listInsights,
            variables() {
                return {
                    limit: this.perPage,
                    offset: 0,
                }
            },
            // watchQuery: {
            //     fetchPolicy: 'network-only'
            // },
            fetchPolicy: 'network-only',
        },
    },
    data() {
        return {
            insights: null,
            offset: 0,
            page: 0,
            perPage: 12,
        };
    },
    methods: {
        async refresh(event) {
            this.page = 0;
            this.offset = 0;
            await this.$apollo.queries.insights.refetch();
            event.target.complete();
        },
        async fetchMore(event) {
            this.page++;
            this.offset = this.page * this.perPage;
            await this.$apollo.queries.insights.fetchMore({
                variables: {
                    limit: this.perPage,
                    offset: this.offset,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    if (!fetchMoreResult) {
                        return previousResult;
                    }
                    return Object.assign({}, previousResult, {
                        insights: [ ...previousResult.insights, ...fetchMoreResult.insights]
                    })
                },
            });
            event.target.complete();
        },
        add() {
            return this.$ionic.modalController.create({
                component: InsightEditor
            })
            .then(m => m.present())
          },
      },
}
</script>
<style scoped>
    .image {
        margin-bottom: 50px;
    }
</style>
