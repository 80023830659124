<template>
    <div>
        <ion-item>
            <ion-label><p>Image file</p></ion-label>
            <ion-button @click="select()" slot="end">{{ label }}</ion-button>
        </ion-item>
        <div v-if="uploading" class="ion-padding">
            <ion-spinner name="bubbles"></ion-spinner>&nbsp;Uploading...
        </div>
        <div class="image-wrapper" v-if="displayValue">
            <img @click="select()" :src="displayValue">
            <ion-icon name="trash" color="light" @click.prevent="clearInput"></ion-icon>
        </div>
    </div>
</template>
<script>
import $media from "@/services/media";
export default {
    name: 'image-input',

    props: ["value"],

    data() {
        return {
            uploading: false,
            displayValue: this.value,
        }
    },

    computed: {
        label() {
            return this.value ? "Change" : "Select";
        }
    },

    methods: {
        async select() {
            const file = await $media.selectFile("image");
            if(!file){
                return false;
            }
            this.displayValue = null;
            this.uploading = true;
            const attachment = await $media.uploadFile(file);
            this.displayValue = attachment.src;
            this.uploading = false;
            this.$emit("input", this.displayValue);

        },

        clearInput() {
            this.displayValue = null;
            this.$emit('input', this.displayValue);
        },
    },
}
</script>
<style scoped>
input[type="file"] {
    display: none;
}
.image-wrapper {
    position: relative;
    background-color: #f2f2f2;
    min-height: 70px;
    margin: 10px;
}
.image-wrapper img {
    max-width: 100%;
}
.image-wrapper ion-icon {
    position: absolute;
    top: 4px;
    right: 4px;
    background: rgba(0, 0, 0, 0.4);
    padding: 7px;
    font-size: 20px;
}
.image-wrapper ion-icon:hover {
    opacity: 0.7;
}
</style>
