<template>
    <div>
        <div ref="subscribers" id="subscribers" />
    </div>
</template>


<script>
    import $opentok from "@/services/opentok.js";
    export default {
        props:{
            otSessionKey:{
                type:String,
                required:true
            }
        },
        data(){
            return {
                streams:[],
                otSession:null,
            }
        },
        computed:{
            subscriberStreams(){
                this.focusedView=this.streams.filter(s=>(s.type == 'subscriber'))[0]
                return this.streams.filter(s=>(s.type == 'publisher'));
            }
        },
        async mounted(){
            this.otSession = $opentok.initSession(this.otSessionKey,'subscriber');
            
            this.otSession.on('streamCreated', (event) =>{
                this.streams.push({type:"subscriber",stream:event.stream});
            });
            await $opentok.joinSession(this.otSession,"subscriber");
            var options={
                insertMode: 'append',
                fitMode: 'contain',
                fitMode: 'contain',width: '100%',height:'700'
            }
            for(var i=0;i<this.streams.length;i++){
                var subscriber = this.otSession.subscribe(this.streams[i].stream, this.$refs.subscribers, options, ()=>{});
            }
            

        },
    }
</script>