export default {
    methods: {
        presentAlert(header, message) {
            return this.$ionic.alertController.create({
                header: header,
                message: message,
                buttons: ['OK'],
            }).then(a => a.present())
        },
    }
};