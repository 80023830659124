<template>
    <ion-item>
        <ion-label position="stacked">{{ label }}</ion-label>
        <ion-textarea 
            @ionInput="setInput($event.target.value)"
            :value="value"
            clear-on-edit="true"
            auto-grow="true"
            inputmode="text"
            v-bind="$attrs"
        ></ion-textarea>
    </ion-item>
</template>

<script>
export default {
    name: 'textarea-field',

    props: {
        label: {
            type: String,
            default: '',
        },
        value: {
            type: String,
            default: '',
        },
    },

    methods: {
        setInput(val) {
            this.$emit('input', val);
        }
    },
}
</script>