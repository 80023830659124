<template>
<ion-page>
	<ion-content>
		<ion-slides @ionSlidesDidLoad="slidesLoaded" @ionSlideWillChange="updateSlideIndex" ref="slides" pager="true" :options="slideOptions">
			<ion-slide v-for="slide in slides" :key="slide.id">
				<ion-card color="primary">
					<img :src="slide.image" />
					<ion-card-header>
						<ion-card-subtitle>{{slide.subtitle}}</ion-card-subtitle>
						<ion-card-title>{{slide.title}}</ion-card-title>
					</ion-card-header>
					<ion-card-content>
						<p>{{slide.message}}</p>
					</ion-card-content>
				</ion-card>
			</ion-slide>
		</ion-slides>
		<ion-button @click="skip()" class="bottom-btn skip-btn" fill="clear" color="medium">Skip</ion-button>
		<ion-button v-if="slideIndex < slides.length - 1" key="next" @click="next()" class="bottom-btn next-btn" fill="clear">Next</ion-button>
		<ion-button v-else key="login" v-router-link="{name:'Login'}" class="bottom-btn login-btn" fill="clear">Sign In</ion-button>
	</ion-content>
</ion-page>
</template>

<script>
import $auth from "@/services/auth.js";

export default {
	name: 'home',

	data() {
		return {
			loggedIn: false,
			user: {},
			slides: [{
					id: 1,
					title: "Welcome",
					subtitle: "Lorem Ipsum",
					image: "https://picsum.photos/500/250",
					message: "C-Suite Circle is a global network of senior business leaders who help each other succeed.\nYou must be invited to join."
				},
				{
					id: 2,
					image: "https://picsum.photos/500/250",
					title: "Members only",
					message: "Please sign in to continue"
				}
			],
			slideIndex: 0,
			slideOptions: {
				preventClicks: false,
			}
		}
	},

	async beforeCreate() {
		const user = await $auth.user()
		if (user) {
			this.loggedIn = true
			this.user = user
			this.$router.replace({
				name: "Home"
			});
		} else if (localStorage.skip_onboarding) {
            this.skip();
		}
	},

	methods: {
		skip() {
			localStorage.skip_onboarding = "1";
			this.$router.replace({
				name: "Login"
			});
		},
		next() {
			this.$refs.slides && this.$refs.slides.slideNext();
		},
		async updateSlideIndex() {
			this.slideIndex = await this.$refs.slides.getActiveIndex();
		},
		slidesLoaded() {
			// Call this after slides are added or removed
			this.$refs.slides.update();
		},
	}
}
</script>

<style scoped>
ion-slides {
	height: 100%;
}

ion-slides ion-slide {
	align-items: normal;
}

ion-card {
	margin-bottom: 50px;
}

.bottom-btn {
	position: absolute;
	bottom: 0;
	z-index: 1;
}

.skip-btn {
	left: 0;
}

.next-btn,
.login-btn {
	right: 0;
}
</style>
