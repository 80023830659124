<template>
    <ion-page>
        <app-header title="Insight Detail">
        <ion-button v-if="editable" @click="showEditor()">Edit</ion-button>
        </app-header>
        <ion-content>
            <ion-spinner v-if="$apollo.loading"></ion-spinner>
            <div v-if="insightDetail">
                <div class="ion-padding">
                    <h1>{{insightDetail.title}}</h1>
                    <div class="tags" v-if="insightDetail.picture">
                        <img class="image" :src="insightDetail.picture" alt="">
                    </div>
                    <div class="tags" v-if="insightDetail.tags">
                        <tag-list :tags="insightDetail.tags"></tag-list>
                    </div>
                    <div class="author">
                        <h6 v-if="insightDetail.authorId"><strong>{{ insightDetail.author.name }}</strong>, {{insightDetail.author.title}} at {{insightDetail.author.companyName}}</h6>
                        <p>{{ insightDetail.publishedAt | moment('MMM DD')}} - {{ insightDetail.duration }}</p>
                    </div>
                    <div class="text" v-html="insightDetail.content"></div>
                    <div v-if="insightDetail.mediaUrl" class="mt-4 embed-responsive embed-responsive-16by9 z-depth-1-half">
                        <iframe class="embed-responsive-item" :src="insightDetail.mediaUrl" allowfullscreen ></iframe>
                    </div>
                </div>
                <comments
                    :comments="insightDetail.comments"
                    :source-id="insightDetail.id"
                    source-type="INSIGHT"
                />
            </div>
        </ion-content>
    </ion-page>
</template>
<script>
import $auth from "@/services/auth.js";
import getInsightDetail from '@/gql/insight/GetInsightDetail.gql';
import deleteInsight from '@/gql/insight/DeleteInsight.gql';
import InsightEditor from '@/components/modals/InsightEditor';
import AppHeader from '@/components/global/AppHeader';
import TagList from '@/components/global/TagList';
import Comments from '@/components/comments/Comments';

export default {
    components: {
        InsightEditor,
        AppHeader,
        TagList,
        Comments,
    },
    apollo: {
        insightDetail: {
        query: getInsightDetail,
            variables() {
                return {
                    id: this.$route.params.id,
                };
            }
        }
    },
    data() {
        return {
            insightDetail: null,
            pictures:[]
        }
    },
    computed : {
        editable () {
            return this.insightDetail && (this.insightDetail.authorId == $auth.user().id)
        }
    },
    mounted() {
        this.$on('close', () => {
            this.$ionic.modalController.dismiss()
        })
    },
    methods: {
        async showEditor () {
            let actionSheet = await this.$ionic.actionSheetController.create({
                header: 'Edit Insight',
                buttons: [
                    {
                        text: 'Edit',
                        handler: () => {
                            this.editInsight()
                        }
                    },
                    {
                        text: 'Delete',
                        role: 'destructive',
                        handler: () => {
                            this.deleteInsight()
                        },
                    },
                ]
            })
            actionSheet.present()
        },
        async editInsight () {
            let initialInsight = { ...this.insightDetail };
            initialInsight.tags = [ ...this.insightDetail.tags ];

            let modal = await this.$ionic.modalController.create({
                component: InsightEditor,
                componentProps: {
                    propsData: {
                        insightID : this.insightDetail.id,
                        initialInsight: initialInsight
                    },
                },
            })
            modal.onDidDismiss().then((data)=>{
                data.data && this.$apollo.queries.insightDetail.refetch()
            })
            await modal.present()
        },
        async deleteInsight () {
            if(confirm('Are you sure you wish to delete this post?')){
                let loadingController = await this.$ionic.loadingController.create({message:'Please wait...'});
                loadingController.present();
                await this.$apollo.mutate({
                    mutation: deleteInsight,
                    variables: {
                        id: this.$route.params.id
                    }
                })
                loadingController.dismiss()
                this.$router.go(-1)
            }
        }
    }
}
</script>
