<template>
    <ion-page>
        <cs-main-header>
          Inbox
          <ion-button v-router-link="{ name: 'ConversationCreate' }" color="medium" fill="clear" title="create new">
              <ion-icon name="create" class="icon-create"></ion-icon>
          </ion-button>
        </cs-main-header>
        <ion-content >
            <ion-refresher slot="fixed" id="refresher" @ionRefresh="refresh($event)">
                <ion-refresher-content></ion-refresher-content>
            </ion-refresher>
            <ion-spinner name="bubbles" class="ion-padding center" v-if="!conversations"/>
            <ion-list class="conversation-list" v-else>
                <conversation-card
                    v-for="conversation in conversations"
                    :key="conversation.id"
                    :conversation="conversation"
                    @deleteConversation="deleteConversation"
                />
                <p v-if="conversations && conversations.length < 1" class="ion-text-wrap ion-padding ion-text-center">
                    No conversations yet
                </p>
            </ion-list>
        </ion-content>
    </ion-page>
</template>

<script>
import $auth from '@/services/auth.js';
import ListConversations from '@/gql/conversations/ListConversations.gql';
import DeleteConversation from '@/gql/conversations/DeleteConversation.gql';
import UpdateConversationStatus from '@/gql/conversations/UpdateConversationStatus.gql';
import AppHeader from '@/components/global/AppHeader';
import ConversationCard from '@/components/conversations/ConversationCard';

export default {
    name: 'conversation-list',

    components: {
        AppHeader,
        ConversationCard,
    },

    apollo: {
        $subscribe: {
            conversations: {
                query: ListConversations,
                variables() {
                    return {
                        personId: this.user.id
                    }
                },
                result({ data }) {
                    this.conversations = data.conversations;
                },
                fetchPolicy: 'cache-and-network',
            },
        },
    },

    data() {
        return {
            conversations: null,
            user: $auth.user(),
        };
    },

    methods: {
        async refresh(event) {
            await this.$apollo.subscriptions.conversations.refresh();
            event.target.complete();
        },

        async deleteConversation(conversationId) {
            this.$ionic.alertController.create({
                header: 'Are you sure you want to delete?',
                message: 'Conversation including all history, will be permanently removed. This action cannot be undone.',
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                    },
                    {
                        text: 'Yes, delete',
                        handler: async () => {
                            await this.$apollo.mutate({
                                mutation: UpdateConversationStatus,
                                variables: {
                                    conversationId: conversationId,
                                    personId: this.user.id,
                                },
                                update: (cache, { data: { update_ConversationParticipant } }) => {
                                    this.conversations.splice(this.conversations.findIndex(conversation => conversation.id === conversationId), 1);

                                    const openConversationCount = update_ConversationParticipant.returning[0].conversation.participants.aggregate.count;

                                    if(openConversationCount < 1) {
                                        this.$apollo.mutate({
                                            mutation: DeleteConversation,
                                            variables: {
                                                conversationId: conversationId,
                                            },
                                        });
                                    }
                                },
                            });
                        }
                    }
                ]
            }).then(a => a.present());
        },
    },
}
</script>
<style scoped>
  ion-content {
    --background: var(--csuite-grey-01);
  }
  .main-header ion-button {
    position: absolute;
    top: 0;
    right: 0;
  }
</style>
