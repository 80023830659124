<template>
    <ion-list>
        <ion-item  @click.prevent="$ionic.popoverController.dismiss({ deleteForMe: true })" button>Delete for me</ion-item>
        <ion-item 
            v-if="user.id == author.id"
            lines="none" 
            @click.prevent="$ionic.popoverController.dismiss({ deleteForEveryone: true })" 
            button
        >
            Delete for everyone
        </ion-item>
    </ion-list>
</template>

<script>
import $auth from '@/services/auth.js';

export default {
    name: 'message-action-popover',
    data() {
        return {
            author: null,
            user: $auth.user(),
        };
    },
}
</script>

<style scoped>
    ion-list {
        margin-bottom: 0;
    }
</style>