<template>
    <ion-card>
        <ion-item lines="none">
            <ion-avatar slot="start">
                <img :src="post.person.picture">
            </ion-avatar>
            <ion-label>
                <h2>{{post.person.name}}</h2>
                <p>{{post.createdAt | moment("from")}}</p>
            </ion-label>
        </ion-item>
        <ion-item lines="none" v-router-link="{ name:'PostDetail', params:post }">
            <ion-label class="ion-text-wrap">
                {{post.message}}
            </ion-label>
        </ion-item>
        <div v-if="post.attachmentType">
            <img v-if="post.attachmentType.includes('image/')" :src="post.attachmentSrc" alt="">
            <video controls v-else-if="post.attachmentType.includes('video/')" :src="post.attachmentSrc" alt=""></video>
            <audio controls v-else-if="post.attachmentType.includes('audio/')" :src="post.attachmentSrc" alt=""></audio>
            <link-preview v-else-if="post.attachmentType === 'link'" :title="post.attachmentName" :link="post.attachmentLink" :description="post.attachmentDescription" :image="post.attachmentSrc"></link-preview>
            <post-file v-else target="_blank" :post="post"></post-file>
        </div>
        <action-bar :article="post"></action-bar>

    </ion-card>
</template>
<script>
import PostFile from '@/components/feed/PostFile';
import LinkPreview from '@/components/feed/LinkPreview';
import ActionBar from '@/components/global/ActionBar';

export default {
    components: {
        PostFile,
        LinkPreview,
        ActionBar
    },
    props: {
        post: {
            type: Object,
            required: true
        }
    }
}
</script>
<style scoped>
    video
    {
        width:100%;
    }
    audio
    {
        width:100%;
    }
</style>
