<template>
    <ion-item class="ion-no-padding" lines="inset">
        <ion-avatar slot="start">
            <img :src="comment.author.picture">
        </ion-avatar>
        <ion-label class="comment">
            <div class="author">
                <h5 class="author-title">
                    <strong>{{ comment.author.name }}</strong>
                    <div v-if="isUserAllowedToEdit">
                        <ion-button @click="presentPopover($event, comment.id)" fill="clear" size="small" color="dark" class="ion-no-padding" style="height: 20px;">
                            <ion-icon slot="icon-only" name="ellipsis-horizontal-outline"></ion-icon>
                        </ion-button>
                    </div>
                </h5>
                <h6>{{ comment.author.title }} <span v-if="comment.author.companyName">at {{  comment.author.companyName }}</span></h6>
            </div>
            <p class="comment ion-text-wrap" v-if="!edit">{{ content }}</p>
            <div v-else class="ion-padding-top">
                <form @submit.prevent="updateComment">
                    <textarea-field v-model="content" placeholder="Write a comment..." required />
                    <ion-button type="submit" fill="clear" size="small" color="dark" class="ion-no-padding" :disabled="editBtnDisabled">
                        <ion-icon slot="icon-only" name="checkmark" size="large"></ion-icon>
                    </ion-button>
                    <ion-button @click="edit = false" fill="clear" size="small" color="dark" class="ion-no-padding">
                        <ion-icon slot="icon-only" name="close" size="large"></ion-icon>
                    </ion-button>
                </form>
            </div>
            <time>{{ comment.created_at | formatDateTime  }}</time>
        </ion-label>
    </ion-item>
</template>

<script>
import $auth from "@/services/auth.js";
import CommentPopover from './CommentPopover';
import TextareaField from '@/components/inputs/TextareaField';

export default {
    name: 'comment-card',

    components: {
        TextareaField,
    },

    props: {
        comment: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            content: this.comment.content,
            edit: false,
            errMsg: null,
        }
    },

    computed: {
        editBtnDisabled() {
            return this.content.trim().length < 1;
        },

        isUserAllowedToEdit() {
            return this.comment.author && this.comment.author.id === $auth.user().id
        },
    },

    methods: {
        async presentPopover(event) {
            const popover = await this.$ionic.popoverController.create({
                component: CommentPopover,
                event: event,
                translucent: true
            });

            popover.onDidDismiss().then(({ data })=>{
                if(data && data.edit) this.edit = data.edit;

                if(data && data.delete) {
                    this.$ionic.alertController.create({
                        header: 'Are you sure you want to delete?',
                        buttons: [
                            { text: 'Cancel', role: 'cancel'},
                            {
                                text: 'Yes, delete',
                                handler: () => {
                                    this.$emit('deleteComment', this.comment.id);
                                }
                            }
                        ]
                    }).then(a => a.present());
                };
            });

            await popover.present();
        },

        updateComment() {
            this.edit = false;
            this.$emit('updateComment', { id: this.comment.id, content: this.content });
        },
    }
}
</script>

<style scoped>
    .comment {
        background-color: #e0e9fd;
        border-radius: 7px;
        overflow: hidden;
        padding: 10px;
        margin-bottom: 8px;
    }
    .comment p {
        padding: 7px 0;
        font-size: 16px;
        line-height: 1.5;
        color: #000000;
    }
    .author {
        color: #3e3e3e;
        overflow: hidden;
    }

    .author-title {
        display: flex; 
        justify-content: space-between; 
        align-items: center;
    }

    time {
        font-size: 13px; 
        color: #817e7e;
    }
</style>