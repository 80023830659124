<template>
    <ion-page>
        <app-header title="Notification Settings"></app-header>
        <ion-content>
            <ion-list v-for="channel in channels" :key="channel.id">
                <ion-list-header>
                    <ion-label>
                        <ion-icon :name="channel.icon"></ion-icon>
                        {{channel.name}}
                    </ion-label>
                </ion-list-header>
                <ion-item v-for="ntype in types" :key="ntype.id" class="preference" @click="toggle(channel, ntype)">
                    <ion-label class="ion-text-wrap">
                        <h3>{{ntype.description}}</h3>
                    </ion-label>
                    <ion-toggle slot="end" color="secondary" :checked="isEnabled(channel, ntype)" @click.stop.capture="toggle(channel, ntype)"></ion-toggle>
                </ion-item>
            </ion-list>
        </ion-content>
    </ion-page>
</template>

<script>
import AppHeader from "@/components/global/AppHeader"
import ListNotificationChannels from "@/gql/notifications/settings/ListNotificationChannels.gql"
import ListNotificationPreferences from "@/gql/notifications/settings/ListNotificationPreferences.gql"
import InsertPreference from "@/gql/notifications/settings/InsertPreference.gql"
import DeletePreference from "@/gql/notifications/settings/DeletePreference.gql"
import $auth from "@/services/auth"

export default {
    components: {
        AppHeader,
    },
    data () {
        return {
        }
    },
    apollo: {
        channels: {
            query: ListNotificationChannels,
            fetchPolicy: 'cache-and-network',
        },
        types: {
            query: ListNotificationChannels,
            fetchPolicy: 'cache-and-network',
        },
        preferences: {
            query: ListNotificationPreferences,
            variables() {
                return {
                    personId: $auth.user().id,
                }
            },
            fetchPolicy: 'cache-and-network',
        },
    },
    methods: {
        toggle(channel, ntype) {
            const pref = this.getPreference(channel, ntype);
            pref ? this.deletePref(pref.id) : this.createPref(channel, ntype);
        },
        async createPref (channel, ntype) {
            await this.$apollo.mutate({
                mutation: InsertPreference,
                variables: {
                    personId: $auth.user().id,
                    channelId: channel.id,
                    typeId: ntype.id,
                    enabled: false,
                },
            }).then(({data:{insert_NotificationPreference:{returning}}}) => {
                this.preferences.push(returning[0]);
            });
        },
        async deletePref (pid) {
            await this.$apollo.mutate({
                mutation: DeletePreference,
                variables: {
                    id: pid
                },
            }).then(() => {
                this.preferences.splice(this.preferences.findIndex(p => p.id === pid),1);
            });
        },
        isEnabled(channel, ntype) {
            const pref = this.getPreference(channel, ntype);
            return pref ? pref.enabled : true;
        },
        getPreference(channel, ntype) {
            if (!this.preferences) return;
            return this.preferences.find(p => (p.channelId === channel.id && p.typeId === ntype.id));
        }
    },
}
</script>
<style scoped>
ion-item.preference ion-label {
    text-transform: capitalize;
}
</style>
