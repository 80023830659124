import InsertReaction from '@/gql/reaction/InsertReaction.gql';
import DeleteReaction from '@/gql/reaction/DeleteReaction.gql';
import $auth from '@/services/auth.js';

export default {
    methods: {
        async addLike() {
            const variables = {
                userId: this.user.id,
                reactionType: 'LIKE',
            };

            if(this.article.__typename === 'Post') {
                variables.postId = this.article.id
            } else if(this.article.__typename === 'Insight') {
                variables.insightId = this.article.id
            } else if(this.article.__typename === 'Event') {
                variables.eventId = this.article.id
            } else if(this.article.__typename === 'Perk') {
                variables.perkId = this.article.id
            } else if(this.article.__typename === 'Job') {
                variables.jobId = this.article.id
            }

            await this.$apollo.mutate({
                mutation: InsertReaction,
                variables: {
                    ...variables
                }
            }).then(({ data }) => {
                this.countLikes ++;
                this.likeReactionDetail = {
                    id: data.insert_Reaction.returning[0].id,
                    reactionType: variables.type,
                    personId: variables.userId
                };
                this.article.likeReaction = [this.likeReactionDetail];
                if (this.article.__typename === 'Post' && this.article.person.id != $auth.user().id) {
                    $notifications.send({
                        recipientId: this.article.person.id,
                        typeId: 'LIKED_POST',
                        itemType: 'post',
                        itemId: this.article.id,
                    })
                }
            });
        },

        async deleteLike(id) {
            await this.$apollo.mutate({
                mutation: DeleteReaction,
                variables: {
                    id: id,
                }
            }).then(() => {
                this.countLikes --;
                this.likeReactionDetail = null;
                this.article.likeReaction = [];
            });
        },
        setLikeReactionDetail() {
            this.likeReactionDetail = this.article.likeReaction && this.article.likeReaction[0];
        }
    }
}
