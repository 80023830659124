import { addIcons } from 'ionicons';
import * as allIcons from 'ionicons/icons';
const currentIcons = Object.keys(allIcons).map(i => {
    return {
        ['ios-' + camelCaseToDash(i)]: allIcons[i],
        ['md-' + camelCaseToDash(i)]: allIcons[i]
    };
});
const iconsObject = Object.assign({}, ...currentIcons);
addIcons(iconsObject);

function camelCaseToDash(myStr) {
    return myStr.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}
