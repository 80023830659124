<template>
    <ion-page>
        <app-header title="Advisor Profile" />
        <ion-content>
            <ion-spinner v-if="$apollo.queries.advisorDetail.loading" name="bubbles" class="spinner"></ion-spinner>
            <div v-else>
                <ion-card>
                    <ion-card-header>
                        <div class="avatar" v-if="advisorDetail.picture">
                            <img :src="advisorDetail.picture" alt="">
                        </div>
                        <div class="ion-text-center">
                            <h3>{{ advisorDetail.name }}</h3>
                        </div>
                    </ion-card-header>
                    <ion-card-content class="card-content">
                        <p v-if="advisorDetail.consultantSkills.length > 0">
                            <ion-chip v-for="(skill, $index) in advisorDetail.consultantSkills" :color="tagColors[$index%6]" :key="skill">{{skill}}</ion-chip>
                        </p>
                        <br/>
                        <p>{{advisorDetail.consultantBio}}</p>
                        <br/>
                        <div class="contact-links">
                            <ion-button v-router-link="{ name: 'ConversationCreate', query: { user: advisorDetail.id }}" color="secondary">
                                <ion-icon name="chatbubble"></ion-icon>Message {{advisorDetail.firstName}}
                            </ion-button>

                            <ion-button v-if="advisorDetail.phone" color="success" :href="'tel:' + advisorDetail.phone">
                                <ion-icon name="call"></ion-icon>Call {{advisorDetail.firstName}}
                            </ion-button>

                            <ion-button v-if="advisorDetail.email" color="medium" :href="'mailto:' + advisorDetail.email + '?subject=Hello, ' + advisorDetail.firstName">
                                <ion-icon name="mail"></ion-icon>Email {{advisorDetail.firstName}}
                            </ion-button>
                        </div>

                    </ion-card-content>
                </ion-card>

                <post-card v-for="post in advisorDetail.posts" :key="post.id" :post="post"></post-card>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import getAdvisorDetail from '@/gql/advisors/GetAdvisorDetail.gql';

import AppHeader from '@/components/global/AppHeader';

export default {
    components: {
        AppHeader,
    },
    data () {
        return {
            tagColors: ['secondary', 'success', 'warning', 'danger', 'primary', 'tertiary']
        }
    },
    apollo: {
        advisorDetail: {
            query: getAdvisorDetail,
            variables() {
                return {
                    id: this.$route.params.id,
                };
            }
        },
    },

}
</script>

<style scoped>
    .spinner {
        display: block;
        margin: 10px auto;
    }
    .avatar {
        max-width: 250px;
        margin: 0 auto;
    }
    .card-content {
        padding-top: 0;
        text-align: center;
    }
    .avatar img {
        background: #f1f1f1;
    }
    ion-icon[name="chatbubble"],
    ion-icon[name="call"],
    ion-icon[name="mail"] {
        margin-right: 7px;
    }
    .contact-links {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 20px;
    }
    .social-media-links ion-button {
        margin-right: 10px;
    }
</style>
