<template>
    <ion-page>
        <app-header title="Event Detail">
    		<ion-buttons v-if="eventDetail && eventDetail.contactPerson.id === userId">
    			<ion-button @click="showEventOptions">
    				<ion-icon slot="icon-only" name="create"></ion-icon>
    			</ion-button>
    		</ion-buttons>
    	</app-header>
        <ion-content>
            <ion-list v-if="eventDetail">
                <ion-card>
                    <img :src="eventDetail.picture" />
                    <ion-card-title>{{eventDetail.name}}</ion-card-title>
                    <ion-card-subtitle v-if="eventDetail.city">-  {{eventDetail.city.name}}</ion-card-subtitle>
                    <ion-chip v-for="tag in eventDetail.tags" :key="tag">
                        <ion-label color="secondary">{{tag}}</ion-label>
                    </ion-chip>
                    <ion-card-content>{{eventDetail.description}} {{status}}</ion-card-content>
                    <!-- Seminar Event -->
                    <div v-if="eventDetail.type=='HOSTED_SEMINAR'">
                        <!-- Ongoing Event -->
                        <div v-if="status='ONGOING'">
                            <!-- user rsvp'd -->
                            <div v-if="eventDetail.responses.length">
                                <b> Ongoing Event</b>
                                <ion-button expand="full" color="primary" v-router-link="{name:'EventSeminar', params:{id:eventDetail.id}}">Join Now</ion-button>
                            </div>
                            <!-- user can rsvp and join ongoing event -->
                            <div v-else>
                                <ion-button expand="full" @click="presentActionSheet" color="primary" v-if="!eventDetail.responses.length">Rsvp now</ion-button>
                            </div>
                        </div>
                        <!-- event not started -->
                        <div v-else-if="status='NOTSTARTED'">
                            <b>Starts {{ eventDetail.start | moment('from') }}</b>
                            <ion-button expand="full" @click="presentActionSheet" color="primary" v-if="!eventDetail.responses.length">Rsvp now</ion-button>
                            <div v-else>
                                <ion-button expand="full" @click="presentActionSheet" color="light" v-if="eventDetail.responses[0].status=='NOT_ATTENDING'">Not Attending</ion-button>
                                <ion-button expand="full" @click="presentActionSheet" color="success" v-else>Attending</ion-button>
                            </div>
                        </div>
                        <!-- event finished -->
                        <div v-else>
                            This Event has ended
                            <ion-button v-if="eventDetail.responses.length" expand="full" color="primary" v-router-link="{name:'EventSeminar', params:{id:eventDetail.id}}">Watch Event</ion-button>
                        </div>
                    </div>
                    <!-- Normal Event -->
                    <div v-else>
                        <ion-button expand="full" @click="presentActionSheet" color="primary" v-if="!eventDetail.responses.length">Rsvp now</ion-button>
                        <div v-else>
                            <ion-button expand="full" @click="presentActionSheet" color="light" v-if="eventDetail.responses[0].status=='NOT_ATTENDING'">Not Attending</ion-button>
                            <ion-button expand="full" @click="presentActionSheet" color="success" v-else>Attending</ion-button>
                        </div>
                    </div>
                </ion-card>
                <company-card v-if="eventDetail.company" heading="Organization Detail:" :company="eventDetail.company" />
                <person-card heading="Host Detail:" :person="eventDetail.contactPerson" v-router-link="{ name: 'MemberDetail', params: { id: eventDetail.contactPerson.id }}" />
            </ion-list>
        </ion-content>
    </ion-page>
</template>

<script>
import getEventDetail from '@/gql/event/GetEventDetail.gql';
import DeleteEvent from '@/gql/event/DeleteEvent.gql';
import AppHeader from '@/components/global/AppHeader';
import CompanyCard from '@/components/cards/CompanyCard'
import PersonCard from '@/components/cards/PersonCard'
import EventEditor from '@/components/event/EventEditor';
import $auth from '@/services/auth.js';
import InsertEventResponse from '@/gql/event/InsertEventResponse.gql';
import UpdateEventResponse from '@/gql/event/UpdateEventResponse.gql';

export default {
    components: {
        AppHeader,
        CompanyCard,
        PersonCard
    },
    apollo: {
        eventDetail: {
            query: getEventDetail,
            variables() {
                return {
                    id: this.$route.params.id,
                    personId: $auth.user().id
                }
            }
        }
    },
    computed:{
        userId() {
			return $auth.user().id;
		},
        status(){
            if(this.eventDetail){
                let endTime = new Date(this.eventDetail.end);
                let timeNow = new Date();
                if(timeNow > endTime){
                    return "FINISHED"
                }
                else{
                    let startTime = new Date(this.eventDetail.start);
                    if(timeNow > startTime){
                        return "ONGOING"
                    }
                    else{
                        return "NOTSTARTED"
                    }
                }
            }
        }
    },
    methods: {
        async createResponse(status){
            await this.$apollo.mutate({
                mutation: InsertEventResponse,
                variables: {
                    eventId: this.eventDetail.id,
                    personId: $auth.user().id,
                    status: status
                }
            })
            this.$apollo.queries.eventDetail.refetch()
        },
        async updateResponse(status){
            await this.$apollo.mutate({
                mutation: UpdateEventResponse,
                variables: {
                    eventId: this.eventDetail.id,
                    personId: $auth.user().id,
                    status: status
                }
            })
            this.$apollo.queries.eventDetail.refetch()
        },
        async presentActionSheet() {
            this.$ionic.actionSheetController
                .create({
                header: 'Albums',
                cssClass: 'my-custom-class',
                buttons: [
                    {
                        text: 'Not Attending',
                        role: 'destructive',
                        icon: 'close',
                        handler:   () => {
                            if(this.eventDetail.responses.length){
                                this.updateResponse('NOT_ATTENDING')
                            }
                            else{
                                this.createResponse('NOT_ATTENDING')
                            }
                        },
                    },
                    {
                        text: 'Attending',
                        icon: 'checkmark',
                        handler: () => {
                            if(this.eventDetail.responses.length){
                                this.updateResponse('ATTENDING')
                            }
                            else{
                                this.createResponse('ATTENDING')
                            }
                        },
                    },
                    {
                        text: 'Cancel',
                        icon: 'close',
                        role: 'cancel',
                    },
                ],
            }).then(a => a.present())
        },
        async showEventOptions() {
			const actionsheet = await this.$ionic.actionSheetController
				.create({
					header: this.eventDetail.name,
					cssClass: 'my-custom-class',
					buttons: [{
							text: 'Edit',
							icon: 'create',
							handler: this.showEventEditor,
						},
						{
							text: 'Delete',
							role: 'destructive',
							icon: 'trash',
							handler: this.confirmDelete,
						},
						{
							text: 'Cancel',
							icon: 'close',
							role: 'cancel',
						},
					],
				});
			actionsheet.present();
		},
		async confirmDelete() {
			const deletePopup = await this.$ionic.alertController.create({
				header: 'Delete ' + this.eventDetail.name + "?",
				message: 'Are you sure you want to delete this event?',
				buttons: [{
						text: 'Cancel',
						role: 'cancel',
						cssClass: 'secondary',
					},
					{
						text: 'Delete',
						handler: this.deleteEvent,
					},
				],
			});
            deletePopup.present();
		},
        async deleteEvent() {
            let loadingController = await this.$ionic.loadingController.create({message:'Please wait...'});
            loadingController.present();
            await this.$apollo.mutate({
                mutation: DeleteEvent,
                variables: {
                    id: this.$route.params.id
                }
            });
            loadingController.dismiss();
            this.$router.go(-1);
        },
        async showEventEditor() {
			const modal = await this.$ionic.modalController.create({
				component: EventEditor,
				componentProps: {
					data: {
						event: this.eventDetail,
					},
				},
			})
			modal.onDidDismiss().then((data) => {
				if (data.data) this.$apollo.queries.eventDetail.refetch()
			})
			await modal.present()
		},
    },
}
</script>
