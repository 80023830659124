<template>
    <ion-page>
        <app-header title="My network" />
        <ion-content>
            <div v-if="pendingConnections && pendingConnections.length">
                <ion-list-header>
                    <ion-label>Pending Connections List</ion-label>
                </ion-list-header>
                <ion-list>
                    <connection-card  
                        v-for="pendingConnection in pendingConnections" 
                        :key="pendingConnection.id" 
                        :connection="pendingConnection" 
                        @refetchConnections="refetchConnections"
                        @refetchPendingConnections="refetchPendingConnections"
                    />
                    <ion-item v-if="pendingConnections && pendingConnections.length < 1">
                        <ion-label><p>No pending connections.</p></ion-label>
                    </ion-item>
                </ion-list>
            </div>

            <ion-list-header>
                <ion-label>Connections List</ion-label>
            </ion-list-header>
            <ion-list>
                <connection-card  
                    v-for="connection in connections" 
                    :key="connection.id"
                    :connection="connection" 
                />
                <ion-item v-if="connections && connections.length < 1">
                    <ion-label><p>No connections.</p></ion-label>
                </ion-item>
             </ion-list>
        </ion-content>
    </ion-page>
</template>

<script>
import $auth from "@/services/auth.js";
import { ListConnections, ListPendingConnections }from '@/gql/connection/ListConnections.gql';

import AppHeader from '@/components/global/AppHeader';
import ConnectionCard from '@/components/connections/ConnectionCard';

export default {
    components: {
        AppHeader,
        ConnectionCard,
    },

    apollo: {
        connections: {
            query: ListConnections,
            variables() {
                return {
                    userId: $auth.user().id
                };
            },
            watchQuery: {
                fetchPolicy: 'network-only'
            },
            fetchPolicy: 'network-only',
        },
        
        pendingConnections: {
            query: ListPendingConnections,
            variables() {
                return {
                    userId: $auth.user().id
                };
            },
            watchQuery: {
                fetchPolicy: 'network-only'
            },
            fetchPolicy: 'network-only',
        },
    },

    data() {
        return {
            connections: null,
            pendingConnections: null,
        };
    },

    methods: {
        refetchConnections() {
            this.$apollo.queries.connections.refetch();
        },

        refetchPendingConnections() {
            this.$apollo.queries.pendingConnections.refetch();
        }
    }
}
</script>
