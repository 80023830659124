<template>
    <div class="action-bar">
        <div class="action-item" v-for="(action,index) in actions" :key="index" @click="action.act" :class="[action.name.toLowerCase(),
         { 'liked' : article.likeReaction.length || likeReactionDetail } ]" >
            <div class="action-icon"> 
                <i :class="'icons8-'+action.icon"></i>
            </div>
            <div class="action-name">
                <span>{{action.name}}</span>
            </div>
            <div v-if="action.name.toLowerCase() === 'like' && article.totalLikes" class="likes-counter">
                {{countLikes}}
            </div>
        </div>
    </div>
</template>

<script>
import InsertReaction from '@/gql/reaction/InsertReaction.gql';
import socialInteractions from "@/mixins/socialInteractions.js"
import $auth from '@/services/auth';

export default {
    data() {
        return {
            actions: [
                {
                    name: "Like",
                    icon: "thumbs-up",
                    act: this.likeToggle
                },
                {
                    name: "Share",
                    icon: "share",
                    act: this.shareItem
                },
                {
                    name: "Invite",
                    icon: "add-person",
                    act: this.inviteMembersToItem
                }
            ],
            likeReactionDetail: null,
            user: $auth.user(),
            countLikes: this.article.totalLikes.aggregate.count || 0,
        };
    },
    props: {
        article: {
            type: Object,
            default: () => {}
        },
    },
    methods: {
        likeToggle: function() {
            if(this.likeReactionDetail) {
                this.deleteLike(this.article.likeReaction[0].id);
            } else {
                this.addLike();
            }
        },
        shareItem: function() {
        },
        inviteMembersToItem: function() {
        }
    },
    mixins: [socialInteractions],
    mounted() {
        this.setLikeReactionDetail();
    }
}
</script>

<style scoped>

.action-bar {
    display: flex;
    margin: 0px -16px;
    padding: 10px 0px;
    border-top: 1px solid var(--csuite-default);
    border-bottom: 1px solid var(--csuite-default);
    background-color: white;
    box-shadow: var(--csuite-box-shadow);
}

.action-bar .action-item {
    margin: auto;
}

.action-bar .action-item > div {
    display: inline-block;
    vertical-align: middle;
    color:#B5C0C7;
}

.action-bar .action-item .action-icon {
    font-size: 24px;
    margin-right: 6px;  
}

.action-bar .action-item.like.liked .action-icon,
.action-bar .action-item.like.liked .action-name{
    color: var(--csuite-primary);
    visibility: initial;
}

.action-bar .action-item .action-name {
    text-transform: uppercase;
    font-size: 12px;
    font-family: var(--csuite-font-family-sans-serif-bold);
}

.action-bar .action-item .likes-counter {
    margin-left: 6px;
    font-family: var(--csuite-font-family-sans-serif-bold);
    font-size: 12px;
    color: var(--csuite-toggle-btn-text-color);
}
</style>