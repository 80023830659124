export default {
    data() {
        return {
            loadingController: null,
        };
    },

    methods: {
        presentLoading() {
            this.loadingController = this.$ionic.loadingController.create({
                message: 'Please wait...',
            });
            return this.loadingController.then(loading => {
                return loading.present()
            });
        },

        dismissLoading() {
            return this.loadingController && this.loadingController.then(loading => {
                loading.dismiss();
            });
        },
    }
};