<template>
    <div>
        <ion-item lines="none">
            <ion-input
                type="text"
                @input="setInput($event.target.value)"
                @keypress.prevent.stop.enter="addTag"
                clear-input
                :value="tag"
                :placeholder="label"
            ></ion-input>
            <ion-button class="add-button" slot="end" @click.prevent.stop="addTag">Add</ion-button>
        </ion-item>
        <ion-item>
            <ion-chip v-for="(tag, index) in tags" :key="index" outline color="primary">
                <ion-label>{{ tag }}</ion-label>
                <ion-icon name="close-circle" @click="tags.splice(index, 1)"></ion-icon>
            </ion-chip>
        </ion-item>
    </div>
</template>

<script>
    export default {
        name: 'tag-input',

        props: {
            label: {
                type: String,
                default: '',
            },
            value: {
                type: Array,
                default: () => []
            }
        },

        data() {
            return {
                tag: '',
                tags: this.value || [],
            };
        },

        watch: {
            tags(n, o) {
                this.$emit('input', n);
            },
        },

        methods: {
            setInput(val) {
                this.tag = val;
            },
            addTag() {
                if (this.tag && ! this.tags.includes(this.tag)) {
                    this.tags.push(this.tag);
                    this.tag = '';
                }
            },
        },
    }
</script>
