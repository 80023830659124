<template>
    <ion-page>
        <app-header title="C-Suite Perks"></app-header>
        <ion-content>
            <ion-fab vertical="bottom" horizontal="end" slot="fixed">
                <ion-fab-button @click="openPerkEditor()" color="primary">
                    <ion-icon name="create"></ion-icon>
                </ion-fab-button>
            </ion-fab>
             <ion-card v-for="perk in perks" :key="perk.id" v-router-link="{name:'PerkDetail', params:{id:perk.id}}">
                <ion-item lines="none">
                    <ion-label>
                        <h2>{{perk.name}}</h2>
                        <p>by - {{perk.company.name}}</p>
                        <ion-chip v-for="tag in perk.tags" :key="tag">
                            <ion-label color="secondary">{{tag}}</ion-label>
                        </ion-chip>
                    </ion-label>
                </ion-item>
                <ion-item lines="none">
                    {{perk.description}}
                </ion-item>
            </ion-card>
        </ion-content>
    </ion-page>
</template>

<script>
import AppHeader from '@/components/global/AppHeader';
import ListPerks from '@/gql/perk/ListPerks.gql';
import PerkEditor from '@/components/modals/PerkEditor';

export default {
    components: {
        AppHeader
    },
    apollo: {
        perks: {
            query: ListPerks
        }
    },
    methods: {
        async openPerkEditor() {
            let modal = await this.$ionic.modalController.create({
                component: PerkEditor
            });

            await modal.present();
        }
    }
}
</script>
