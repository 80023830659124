<template>
    <div>
        <span class="badge" v-for="(tag, index) in tags" :key="index">
            {{ tag }}
        </span>
    </div>
</template>
<script>
    export default {
        name: 'tag-list',
        props: {
            tags: {
                type: Array,
                default: []
            }
        }
    }
</script>
<style scoped>
    .badge {
        font-size: 12px;
        font-weight: 700;
        padding: 7px 7px;
        display: inline-block;
        background-color: #8e989f;
        color: #fff;
        letter-spacing: .5px;
        text-transform: uppercase;
        margin-left: 22px;
    }
</style>
