<template>
    <ion-page>
        <app-header title="Event Seminar" />
        <ion-content v-if="eventDetail">
            <div v-if="isFinished">
                This session has now ended
            </div>
            <div v-else>
                <div v-if="isOngoing">
                    <seminar-screen v-if="eventDetail" :otSessionKey="eventDetail.otSession" />
                </div>
                <div v-else>
                    <b>Starts {{ eventDetail.start | moment('from') }}</b>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import AppHeader from '@/components/global/AppHeader';
import SeminarScreen from '@/components/seminar/SeminarScreen'
import getEventDetail from '@/gql/event/GetEventDetail.gql';
import $auth from '@/services/auth.js';

export default {
    components: {
        AppHeader,
        SeminarScreen
    },
    apollo: {
        eventDetail: {
            query: getEventDetail,
            variables() {
                return {
                    id: this.$route.params.id,
                    personId: $auth.user().id
                }
            }
        }
    },
    computed:{
        isFinished(){
            if(this.eventDetail){
                let endTime = new Date(this.eventDetail.end);
                let timeNow = new Date();
                return (timeNow > endTime);
            }
        },
        isOngoing() {
            if(this.eventDetail){
                let startTime = new Date(this.eventDetail.start);
                let timeNow = new Date();
                return (timeNow > startTime);
            }
        }
    },
}
</script>

