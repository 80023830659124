import Vue from 'vue'
import Ionic from '@ionic/vue'
import '@ionic/core/css/core.css'
import '@ionic/core/css/ionic.bundle.css'
import icons from './icons'

import 'cs-components';
import 'cs-components/dist/csuite.css';

import '@/style.css' // Global style
import '@/style/fonts.css';
import "@/assets/icons/css/styles.css";
import '@/style/csuite.variables.css';

import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

// Enable ionic
Vue.use(Ionic, {
    mode: "ios"
});

import VueMoment from 'vue-moment';
Vue.use(VueMoment);

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

import Clipboard from "v-clipboard";
Vue.use(Clipboard);

import VueApollo from 'vue-apollo';
Vue.use(VueApollo);

import { apolloClient } from '@/services/apollo';

const apolloProvider = new VueApollo({
    defaultClient: apolloClient
});

// Add a universal router-link directive so you can use it as an attribute
Vue.directive('router-link', {
    inserted: function(el, binding, vnode) {
        el.addEventListener("click", function() {
            vnode.context.$router.push(binding.value);
        })
    }
});


Vue.filter('formatDateTime', (date) => {
    if (!date) return '';

    if(Vue.moment(date).isSame(Vue.moment(), 'day')) {
        return Vue.moment(date).fromNow();
    } else if(Vue.moment(date).isSame(Vue.moment(), 'year')) {
        return Vue.moment(date).format('MMM D');
    } else {
        return Vue.moment(date).format('D/M/YYYY');
    }
});

// Run the app
new Vue({
    apolloProvider,
    router,
    render: h => h(App)
}).$mount('#app')
