import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';

// Subscriptions
import { WebSocketLink } from 'apollo-link-ws';
import { HttpLink } from 'apollo-link-http';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';

const wsLink = new WebSocketLink({
    uri: "wss://v1.csuitegraph.com/v1/graphql",
    options: {
      reconnect: true
    }
});

const httpLink = new HttpLink({
    uri: "https://v1.csuitegraph.com/v1/graphql",
});
  
const link = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpLink,
);

export const apolloClient = new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
    // uri: 'https://v1.csuitegraph.com/v1/graphql'
});