<template>
    <ion-item :href="post.attachmentSrc" target="_blank" lines="none">
        <ion-thumbnail slot="start">
            <img :src="icon">
        </ion-thumbnail>
        <ion-label class="ion-text-wrap">{{post.attachmentName || 'Untitled File'}}</ion-label>
    </ion-item>
</template>
<script>
    export default {
        props: {
            post: {
                type: Object,
                required: true
            }
        },
        computed: {
            icon () {
                const map = {
                    'application/pdf': 'pdf.png'
                }
                return 'https://img.icons8.com/ios-filled/50/000000/' + (map[this.post.attachmentType] || 'file.png')
            }
        }
    }
</script>
