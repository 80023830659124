<template>
    <ion-page>
        <app-header title="Post Detail" >
            <ion-button v-if="editable" @click="showOptions()">Edit</ion-button>
        </app-header>
        <ion-content>
            <ion-spinner v-if="!post"></ion-spinner>
            <post-card v-if="post" :post="post"></post-card>
        </ion-content>
    </ion-page>
</template>

<script>
import AppHeader from '@/components/global/AppHeader';
import PostCard from '@/components/feed/PostCard';
import GetPost from '@/gql/feed/GetPost.gql';
import PostComposer from '@/components/feed/PostComposer.vue';
import DeletePost from '@/gql/feed/DeletePost.gql';
import $auth from '@/services/auth';

export default {
    components: {
        AppHeader,
        PostCard
    },
    apollo: {
        post: {
            query: GetPost,
            variables () {
                return {
                    id: this.$route.params.id,
                    currentUserId: $auth.user().id
                }
            },
        }
    },
    data () {
        return {
        }
    },
    computed : {
        editable () {
            return this.post && (this.post.person.id == $auth.user().id)
        }
    },
    methods: {
        async showOptions () {
            let actionSheet = await this.$ionic.actionSheetController.create({
                header: 'Manage Post',
                buttons: [
                    {
                        text: 'Edit',
                        handler: () => {
                            this.showEditor()
                        }
                    },
                    {
                        text: 'Delete',
                        role: 'destructive',
                        handler: () => {
                            this.deletePost()
                        },
                    },
                ]
            })
            actionSheet.present()
        },
        async showEditor () {
            let modal = await this.$ionic.modalController.create({
                component: PostComposer,
                componentProps: {
                    data: {...this.post},
                },
            })
            modal.onDidDismiss().then((data)=>{
                this.$apollo.queries.post.refetch()
            })
            await modal.present()
        },
        async deletePost () {
            if(confirm('Are you sure you wish to delete this post?')){
                let loadingController = await this.$ionic.loadingController.create({message:'Please wait...'});
                loadingController.present();
                await this.$apollo.mutate({
                    mutation: DeletePost,
                    variables: {
                        id: this.$route.params.id
                    }
                })
                loadingController.dismiss()
                this.$router.go(-1)
            }
        }
    }
}
</script>
