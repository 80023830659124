<template>
    <ion-page>
        <app-header title="C-Suite Advisor"></app-header>
        <ion-content>
            <ion-refresher slot="fixed" id="refresher" @ionRefresh="refresh($event)">
                <ion-refresher-content></ion-refresher-content>
            </ion-refresher>
            <ion-spinner v-if="$apollo.loading" name="bubbles" class="spinner"></ion-spinner>
            <ion-list>
                <member-card v-for="advisor in advisors" :key="advisor.id" :member="advisor" v-router-link="{name:'AdvisorDetail', params:{id:advisor.id}}" detail/>
            </ion-list>
            <infinite-scroll @fetchMore="fetchMore" />
        </ion-content>
    </ion-page>
</template>
<script>
import listAdvisors from '@/gql/advisors/ListAdvisors.gql';
import AppHeader from '@/components/global/AppHeader';
import InfiniteScroll from '@/components/global/InfiniteScroll';
import MemberCard from '@/components/cards/MemberCard';

export default {
    components: {
        AppHeader,
        InfiniteScroll,
        MemberCard,
    },
    apollo: {
        advisors: {
            query: listAdvisors,
            variables() {
                return {
                    limit: this.perPage,
                    offset: 0,
                }
            },
            watchQuery: {
                fetchPolicy: 'cache-and-network'
            },
            fetchPolicy: 'cache-and-network',
        },
    },
    data() {
        return {
            advisors: null,
            offset: 0,
            page: 0,
            perPage: 12,
        };
    },
    methods: {
        async refresh(event) {
            this.page = 0;
            this.offset = 0;
            await this.$apollo.queries.advisors.refetch();
            event.target.complete();
        },

        async fetchMore(event) {
            try {
                this.page++;
                this.offset = this.page * this.perPage;
                await this.$apollo.queries.advisors.fetchMore({
                    variables: {
                        limit: this.perPage,
                        offset: this.offset,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        if (!fetchMoreResult) {
                            return previousResult;
                        }
                        return Object.assign({}, previousResult, {
                            advisors: [ ...previousResult.advisors, ...fetchMoreResult.advisors]
                        });
                    },
                });
            } catch(err) {
                console.log(err);
            }
            event.target.complete();
        },
    }
}
</script>

<style scoped>
    img {
        background: #f1f1f1;
    }
    .spinner {
        display: block;
        margin: 10px auto;
    }
</style>
