<template>
    <div class="connection">
        <ion-button v-if="connectionStatus === 'PENDING'" color="primary" @click.prevent="approveRequest" :disabled="loading">
            <ion-icon name="person-add"></ion-icon>Accept Request
        </ion-button>
        <ion-button  v-else-if="connectionStatus === 'REQUESTED'" color="medium" disabled="true">
            <ion-icon name="person-add"></ion-icon>Request Sent
        </ion-button>
        <ion-button v-else-if="connectionStatus === 'APPROVED'" color="light" @click.prevent="removeFromMyNetwork" :disabled="loading">
            <ion-icon name="person-remove"></ion-icon>Remove from My Network
        </ion-button>
        <ion-button v-else color="primary" @click.prevent="addToMyNetwork" :disabled="loading">
            <ion-icon name="person-add"></ion-icon>Add to My Network
        </ion-button>
    </div>
</template>

<script>
import $auth from '@/services/auth.js';
import InsertConnection from '@/gql/connection/InsertConnection.gql';
import UpdateConnection from '@/gql/connection/UpdateConnection.gql';
import GetConnectionStatus from '@/gql/connection/GetConnectionStatus.gql';
import UpdateInvitationCredit from '@/gql/members/UpdateInvitationCredit.gql';
import connectionMixin from '@/mixins/connectionMixin';

export default {
    name: 'connection-btn',

    props: {
        member: {
            type: Object,
            required: true,
        },
    },

    mixins: [ connectionMixin ],

    data() {
        return {
            loading: false,
        };
    },

    methods: {
        async addToMyNetwork() {
            if (this.user.invitationCredits > 0) {
                this.loading = true;

                await this.$apollo.mutate({
                    mutation: InsertConnection,
                    variables: {
                        fromPersonId: $auth.user().id,
                        toPersonId: this.member.id,
                    },
                    update: async(cache, { data }) => {
                        this.updateConnectionCache(cache, data.insert_Connection);
                    },
                }).then(() => {
                    this.updateInvitationCredit();
                }).catch((err) => console.log(err));

                this.loading = false;
            } else {
                await this.$ionic.alertController.create({
                    header: 'Insufficient credits',
                    buttons: [{ text: 'Cancel', role: 'cancel' }]
                }).then(a => a.present());
            }
        },

        async approveRequest() {
            this.loading = true;

            await this.$apollo.mutate({
                mutation: UpdateConnection,
                variables: {
                    fromPersonId: $auth.user().id,
                    toPersonId: this.member.id,
                    status: 'APPROVED',
                },
                update: (cache, { data }) => {
                    this.updateConnectionCache(cache, data.updateCurrentUserConnection);
                },
            }).catch(err => console.log(err));

            this.loading = false;
        },

        async removeFromMyNetwork() {
            this.loading = true;
            
            await this.$ionic.alertController.create({
                cssClass: 'alert-custom-class',
                header: `Are you sure you want to remove ${this.member.name} from your network?`,
                buttons: [
                    {
                        text: 'Remove',
                        cssClass: 'btn-danger',
                        handler: async () => {
                            await this.$apollo.mutate({
                                mutation: UpdateConnection,
                                variables: {
                                    fromPersonId: $auth.user().id,
                                    toPersonId: this.member.id,
                                    status: 'DECLINED',
                                },
                                update: (cache, { data }) => {
                                    this.updateConnectionCache(cache, data.updateCurrentUserConnection);
                                },
                            }).catch(err => console.log(err));
                        }
                    },
                    {
                        text: 'Cancel',
                        role: 'cancel',
                    },
                ]
            }).then(a => a.present());

            this.loading = false;
        },

        async updateInvitationCredit() {
            await this.$apollo.mutate({
                mutation: UpdateInvitationCredit,
                variables: {
                    id: $auth.user().id,
                    invitationCredits: this.user.invitationCredits - 1,
                },
                update: async (client, { data: { update_Person } }) => {
                    this.user = update_Person.returning[0];
                },
            }).catch(err => console.log(err));
        },


        async updateConnectionCache(cache, data) {
            let { connection } = await cache.readQuery({
                query: GetConnectionStatus,
                variables: {
                    fromPersonId: $auth.user().id,
                    toPersonId: this.member.id
                }
            });

            let connection_updated = data.returning;

            await cache.writeQuery({
                query: GetConnectionStatus,
                variables: {
                    fromPersonId: $auth.user().id,
                    toPersonId: this.member.id
                },
                data: {
                    'connection': connection_updated
                }
            });
        },
    }
}
</script>

<style>
    .alert-custom-class .alert-wrapper .btn-danger {
        color: #F44336;
    }
</style>

<style scoped>
    .connection ion-icon {
        margin-right: 7px;
    }
</style>
