
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PerkFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Perk"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"description"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"tags"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"claimType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"claimCode"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"claimUrl"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"claimEmail"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"company"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"picture"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":191}};
    doc.loc.source = {"body":"fragment PerkFragment on Perk {\n    id\n    name\n    description\n    tags\n    claimType\n    claimCode\n    claimUrl\n    claimEmail\n    company {\n        id\n        name\n        picture\n    }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
