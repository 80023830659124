<template>
     <div class="composer">
        <div class="media-holder">
            <ion-spinner v-if="uploading" name="bubbles"></ion-spinner>
            <div v-if="!uploading && attachmentType" class="media-wrapper">
                <ion-icon name="trash" color="danger" @click.prevent="removeAttachment"></ion-icon>
                <img v-if="attachmentType.includes('image')" :src="attachmentSrc">
                <video v-else-if="attachmentType.includes('video')" controls :src="attachmentSrc" alt=""></video>
                <audio v-else-if="attachmentType.includes('audio')" controls :src="attachmentSrc"></audio>
                <post-file v-else :post="conversationMessage"></post-file>
            </div>
        </div>
        <div class="input-holder">
            <button @click.prevent="openActionSheet" class="icon">
                <svg class="attach-icon" fill="#000000" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 26 26" width="26px" height="26px"><path d="M 19.625 0.15625 C 17.996094 0.234375 16.429688 0.945313 15.21875 2.15625 L 10.46875 6.90625 C 11.214844 6.15625 13.984375 6.671875 14.65625 7.34375 L 17.53125 4.46875 C 18.167969 3.832031 18.964844 3.441406 19.78125 3.40625 C 20.335938 3.378906 21.121094 3.496094 21.8125 4.1875 C 22.457031 4.832031 22.59375 5.582031 22.59375 6.09375 C 22.59375 6.949219 22.203125 7.800781 21.53125 8.46875 L 16.53125 13.5 C 15.273438 14.757813 13.359375 14.859375 12.25 13.75 C 11.617188 13.117188 10.574219 13.113281 9.9375 13.75 C 9.300781 14.386719 9.300781 15.425781 9.9375 16.0625 C 11.078125 17.203125 12.578125 17.78125 14.125 17.78125 C 15.796875 17.78125 17.503906 17.097656 18.8125 15.78125 L 23.84375 10.78125 C 25.121094 9.507813 25.84375 7.804688 25.84375 6.09375 C 25.84375 4.503906 25.242188 2.992188 24.125 1.875 C 22.929688 0.679688 21.324219 0.078125 19.625 0.15625 Z M 11.875 8.21875 C 10.203125 8.21875 8.46875 8.90625 7.15625 10.21875 L 2.15625 15.21875 C 0.878906 16.492188 0.15625 18.195313 0.15625 19.90625 C 0.15625 21.496094 0.757813 23.007813 1.875 24.125 C 3.070313 25.320313 4.675781 25.921875 6.375 25.84375 C 8.003906 25.765625 9.570313 25.054688 10.78125 23.84375 L 15.53125 19.09375 C 14.78125 19.84375 12.015625 19.328125 11.34375 18.65625 L 8.46875 21.53125 C 7.832031 22.167969 7.035156 22.554688 6.21875 22.59375 C 5.664063 22.621094 4.878906 22.503906 4.1875 21.8125 C 3.542969 21.167969 3.40625 20.414063 3.40625 19.90625 C 3.40625 19.050781 3.796875 18.199219 4.46875 17.53125 L 9.46875 12.5 C 10.726563 11.242188 12.640625 11.144531 13.75 12.25 C 14.386719 12.886719 15.429688 12.886719 16.0625 12.25 C 16.699219 11.613281 16.699219 10.574219 16.0625 9.9375 C 14.921875 8.796875 13.417969 8.21875 11.875 8.21875 Z"/></svg>
                <!-- <img slot="icon-only" src="https://img.icons8.com/windows/32/000000/attach.png"/> -->
            </button>
            <ion-textarea
                placeholder="Type your message"
                @ionChange="$event => message = $event.target.value"
                :value="message"
                clear-on-edit="true"
                class="conversation-textarea"
                rows="1"
            ></ion-textarea>
            <button @click.prevent="sendMessage" type="submit" :disabled="disableSubmitBtn"  class="icon">
                <svg class="send-icon" fill="#000000" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="24px" height="24px">    <path d="M 22 2 L 2 9.2714844 L 7.4375 14.710938 L 18 6 L 9.2890625 16.5625 L 14.728516 22 L 22 2 z"/></svg>
            </button>
        </div>
    </div>
</template>

<script>
import $media from '@/services/media';
import PostFile from '@/components/feed/PostFile.vue';

export default {
    name: 'conversation-composer',

    components: {
        PostFile,
    },

    props: {
        disabled: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            message: null,
            attachmentName: null,
            attachmentType: null,
            attachmentSrc: null,
            hasAttachment: false,
            uploading: false,
        };
    },

    computed: {
        disableSubmitBtn() {
            return (!this.message && !this.hasAttachment) || this.disabled;
        },

        conversationMessage() {
            return {
                message: this.message,
                attachmentName: this.attachmentName,
                attachmentType: this.attachmentType,
                attachmentSrc: this.attachmentSrc
            }
        }
    },

    methods: {
        async openActionSheet() {
            this.actionSheet = await this.$ionic.actionSheetController.create({
                cssClass: 'media-upload',
                buttons: [
                    {
                        text: 'Image',
                        icon: 'camera',
                        handler: () => this.attach('image'),
                    },
                    {
                        text: 'Video',
                        icon: 'videocam',
                        handler: () =>  this.attach('video'),
                    },
                    {
                        text: 'Audio',
                        icon: 'musical-notes',
                        handler: () => this.attach('audio'),
                    },
                    {
                        text: 'Document',
                        icon: 'document',
                        handler: () => this.attach('document'),
                    },
                    {
                        text: 'Cancel',
                        role: 'cancel',
                    },
                ]
            });

            await this.actionSheet.present();
        },

        async attach (type) {
            const file = await $media.selectFile(type);
            if(!file){
                return false;
            }
            await this.$ionic.actionSheetController.dismiss(false);
            this.uploading = true;
            const attachment = await $media.uploadFile(file);
            this.attachmentName = attachment.name;
            this.attachmentType = attachment.contentType;
            this.attachmentSrc = attachment.src;
            this.hasAttachment = true;
            this.uploading = false;
        },

        sendMessage() {
            this.$emit('send', this.conversationMessage);
            this.message = null;
            this.removeAttachment();
        },

        removeAttachment() {
            this.attachmentName = null;
            this.attachmentType = null;
            this.attachmentSrc = null;
            this.hasAttachment = false;
        },
    }
}
</script>

<style scoped>
.media-upload .action-sheet-button .action-sheet-icon {
    margin-right: 10px !important;
}
.composer {
    background-color: var(--csuite-white);
}
.input-holder {
  display: flex;
  padding: 0 16px;
}
ion-textarea {
  background: #ffffff;
  border-radius: 5px;
  padding: 10px 0;
  font-size: 16px;
  flex: 1;
  --placeholder-opacity: 1;
  --placeholder-color: #B5C0C7;
}
button.icon {
    padding: 12px;
    background-color: rgba(0,0,0,0);
}
button.icon svg {
  height: 18px;
  width: 18px;
  fill: var(--csuite-grey-03);
}
button.icon svg.send-icon {
  transform: rotate(45deg);
  fill: #000000;
}

.media-wrapper {
    position: relative;
    margin-top: 10px auto;
    text-align: center
}
.media-wrapper img,
.media-wrapper video,
.media-wrapper audio {
    max-width: 100%;
    max-height: 200px;
}
.media-wrapper ion-icon {
    position: absolute;
    right: 0;
    font-size: 23px;
    z-index: 3;
}
</style>
