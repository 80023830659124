<template>
<div class="ion-page">
	<app-header title="Home" />
	<ion-content>
        <ion-list>
            <ion-item lines="none">
                <ion-label class="ion-text-wrap ion-text-center">
                    <h2>
                        {{greeting}}, {{ user.firstName }}!
                        <br/>
                        Welcome to C-Suite Circle.
                    </h2>
                </ion-label>
            </ion-item>
            <ion-card>
                <ion-card-header>
                    <!-- <ion-card-title>Upcoming Events</ion-card-title> -->
                    <ion-card-subtitle>Upcoming Events</ion-card-subtitle>
                </ion-card-header>

                <div v-if="events">
                    <ion-item v-for="event in events" :key="event.key" v-router-link="{name:'EventDetail', params:{id:event.id}}" detail lines="none">
                        <ion-thumbnail slot="start">
                            <img :src="event.picture"/>
                        </ion-thumbnail>
                        <ion-label class="ion-text-wrap">
                            <h2>{{event.name}}</h2>
                        </ion-label>
                    </ion-item>
                </div>
                <div v-else>
                    <ion-item v-for="i in 3" :key="i">
                        <ion-thumbnail slot="start">
                            <ion-skeleton-text style="width:100%"></ion-skeleton-text>
                        </ion-thumbnail>
                        <ion-label>
                            <h2>
                                <ion-skeleton-text style="width: 70%"></ion-skeleton-text>
                            </h2>
                        </ion-label>
                    </ion-item>
                </div>

                <ion-item lines="none" v-router-link="{name:'EventList'}">
                    <ion-button fill="clear" size="medium" slot="end">See More</ion-button>
                </ion-item>
            </ion-card>

        </ion-list>
	</ion-content>
</div>
</template>

<script>
import AppHeader from '@/components/global/AppHeader';
import $auth from '@/services/auth.js';
import UpcomingEvents from '@/gql/home/UpcomingEvents.gql';

export default {
	components: {
		AppHeader,
	},

	data() {
		return {
			user: $auth.user()
		}
	},

	computed: {
		greeting() {
            const hours = new Date().getHours();
            if (hours >= 18 || hours <= 3) {
                return 'Good evening'
            } else if (hours >= 12) {
                return 'Good afternoon'
            } else {
                return 'Good morning'
            }
		}
	},

	apollo: {
		events: {
			query: UpcomingEvents,
			variables() {
				return {
					personId: this.user.id,
                    where: {
                        start: {
                            _gt: new Date().toISOString()
                        }
                    }
				}
			}
		}
	},

	methods: {
		async showPostComposer() {
			let modal = await this.$ionic.modalController.create({
				component: PostComposer
			})
			modal.onDidDismiss().then((data) => {
				if (data) this.$apollo.queries.posts.refetch()
			})
			await modal.present()
		}
	}
}
</script>
