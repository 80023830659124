import Vue from 'vue'
import {IonicVueRouter} from '@ionic/vue';
Vue.use(IonicVueRouter);

import $auth from '@/services/auth'

// Onboarding
import Login from './views/login/Login.vue';
import Welcome from './views/login/Welcome.vue';
import EnableNotifications from './views/login/EnableNotifications.vue';

import Tabs from './views/Tabs.vue'
import Menu from './views/menu/Menu.vue'

import Home from './views/home/Home.vue'

import Feed from './views/feed/Feed.vue'
import PostDetail from './views/feed/PostDetail.vue'

import EventList from './views/events/EventList.vue'
import EventDetail from './views/events/EventDetail.vue'
import EventSeminar from './views/events/EventSeminar.vue'

import ConversationList from './views/conversations/ConversationList.vue'
import ConversationDetail from './views/conversations/ConversationDetail.vue'
import ConversationCreate from './views/conversations/ConversationCreate.vue'

import NotificationList from './views/notifications/NotificationList.vue'
import NotificationSettings from './views/notifications/NotificationSettings.vue'

import InsightList from './views/insights/InsightList.vue'
import InsightDetail from './views/insights/InsightDetail.vue'

import MemberList from './views/members/MemberList.vue'
import MemberDetail from './views/members/MemberDetail.vue'

import PerkList from './views/perks/PerkList.vue'
import PerkDetail from './views/perks/PerkDetail.vue'

import JobList from './views/jobs/JobList.vue'
import JobDetail from './views/jobs/JobDetail.vue'

import IntroductionList from './views/introduction/IntroductionList.vue'

import AdvisorList from './views/advisors/AdvisorList.vue'
import AdvisorDetail from './views/advisors/AdvisorDetail.vue'

import DevTools from './views/devtools/DevTools.vue'

import Me from './views/me/Me.vue'

import ConnectionList from './views/connections/ConnectionList.vue';

const routes = [{
        path: '/tabs',
        name: 'tabs',
        component: Tabs,
        children: [
            {
                path: 'home',
                name: 'Home',
                components: {
                    home: Home
                }
            },
            {
                path: 'home/posts',
                name: 'Feed',
                components: {
                    home: Feed
                }
            },
            {
                path: 'home/posts/:id',
                name: 'PostDetail',
                components: {
                    home: PostDetail
                }
            },
            {
                path: 'events',
                name: 'EventList',
                components: {
                    events: EventList
                }
            },
            {
                path: 'events/:id',
                name: 'EventDetail',
                components: {
                    events: EventDetail
                }
            },
            {
                path: 'events/:id/seminar',
                name: 'EventSeminar',
                components: {
                    events: EventSeminar
                }
            },
            {
                path: 'conversations',
                name: 'ConversationList',
                components: {
                    conversations: ConversationList
                }
            },
            {
                path: 'conversations/new',
                name: 'ConversationCreate',
                components: {
                    conversations: ConversationCreate
                }
            },
            {
                path: 'conversations/:id',
                name: 'ConversationDetail',
                components: {
                    conversations: ConversationDetail
                }
            },
            {
                path: 'notifications',
                name: 'NotificationList',
                components: {
                    notifications: NotificationList
                }
            },
            {
                path: 'notifications/settings',
                name: 'NotificationSettings',
                components: {
                    notifications: NotificationSettings
                }
            },
            {
                path: 'more',
                name: 'Menu',
                components: {
                    more: Menu
                }
            },
            {
                path: 'more/insights',
                name: 'InsightList',
                components: {
                    more: InsightList
                }
            },
            {
                path: 'more/insights/:id',
                name: 'InsightDetail',
                components: {
                    more: InsightDetail
                }
            },
            {
                path: 'more/jobs',
                name: 'JobList',
                components: {
                    more: JobList
                }
            },
            {
                path: 'more/jobs/:id',
                name: 'JobDetail',
                components: {
                    more: JobDetail
                },
                meta: {
                    hideTabs: true
                }
            },
            {
                path: 'more/members',
                name: 'MemberList',
                components: {
                    more: MemberList
                }
            },
            {
                path: 'more/members/:id',
                name: 'MemberDetail',
                components: {
                    more: MemberDetail
                }
            },
            {
                path: 'more/perks',
                name: 'PerkList',
                components: {
                    more: PerkList
                }
            },
            {
                path: 'more/perks/:id',
                name: 'PerkDetail',
                components: {
                    more: PerkDetail
                }
            },
            {
                path: 'more/introduction',
                name: 'IntroductionList',
                components: {
                    more: IntroductionList
                }
            },
            {
                path: 'more/advisors',
                name: 'AdvisorList',
                components: {
                    more: AdvisorList
                }
            },
            {
                path: 'more/advisors/:id',
                name: 'AdvisorDetail',
                components: {
                    more: AdvisorDetail
                }
            },
            {
                path: 'more/me',
                name: 'Me',
                components: {
                    more: Me
                }
            },
            {
                path: 'more/connections',
                name: 'ConnectionList',
                components: {
                    more: ConnectionList
                },
            },
            {
                path: 'more/devtools',
                name: 'DevTools',
                components: {
                    more: DevTools
                }
            },
        ]
    },
    {
        path: '/onboarding/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/onboarding/welcome',
        name: 'Welcome',
        component: Welcome
    },
    {
        path: '/onboarding/notifications',
        name: 'EnableNotifications',
        component: EnableNotifications
    },
    {
        path: '/',
        redirect: () => {
            return $auth.hasValidToken() ? '/tabs/home' : '/onboarding/welcome';
        }
    }
]

const router = new IonicVueRouter({
    routes
});
router.beforeEach(async (to, from, next) => {
    if($auth.hasValidToken()){
        await $auth.login()
        next()
    }
    else{
        next();
    }
});

export default router;
