<template>
<div>
    <ion-header>
        <ion-toolbar>
            <ion-buttons slot="start">
                <ion-button @click="dismiss()">
                    <ion-icon slot="icon-only" name="close"></ion-icon>
                </ion-button>
            </ion-buttons>
            <ion-title>Add a perk</ion-title>
            <ion-buttons slot="end">
                <ion-button @click="save()">Save</ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
        <ion-item>
            <ion-label position="stacked">Name</ion-label>
            <ion-input :value="perk.name" @ionChange="perk.name = $event.target.value"></ion-input>
        </ion-item>
        <ion-item>
            <ion-label position="stacked">Description</ion-label>
            <ion-textarea :value="perk.description" @ionChange="perk.description = $event.target.value"></ion-textarea>
        </ion-item>
        <ion-item>
            <ion-label position="stacked">Tags</ion-label>
            <tag-input v-model="perk.tags"></tag-input>
        </ion-item>
        <ion-item>
            <ion-label position="stacked">Claim type</ion-label>
            <ion-select :value="perk.claimType" interface="action-sheet" @ionChange="perk.claimType = $event.target.value">
                <ion-select-option value="CODE">Code</ion-select-option>
                <ion-select-option value="LINK">Link</ion-select-option>
                <ion-select-option value="ENQUIRY">Enquiry</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item v-if="perk.claimType=='ENQUIRY'">
            <ion-label position="stacked">Claim email</ion-label>
            <ion-input type="email" :value="perk.claimEmail" @ionChange="perk.claimEmail = $event.target.value"></ion-input>
        </ion-item>
        <ion-item v-if="perk.claimType=='LINK'">
            <ion-label position="stacked">Claim URL</ion-label>
            <ion-input type="url" :value="perk.claimUrl" @ionChange="perk.claimUrl = $event.target.value"></ion-input>
        </ion-item>
        <ion-item v-if="perk.claimType=='CODE'">
            <ion-label position="stacked" >Claim Code</ion-label>
            <ion-input :value="perk.claimCode" @ionChange="perk.claimCode = $event.target.value"></ion-input>
        </ion-item>
    </ion-content>
</div>
</template>

<script>
import TagInput from '@/components/inputs/TagInput';
import {apolloClient} from '@/services/apollo';
import $auth from '@/services/auth';
import InsertPerk from '@/gql/perk/InsertPerk.gql';
import ListPerks from '@/gql/perk/ListPerks.gql';
import UpdatePerk from '@/gql/perk/UpdatePerk.gql';
import GetPerkDetail from '@/gql/perk/GetPerkDetail.gql';

export default {
    components: {
        TagInput
    },
    data() {
        return {
            uploading: false,
            perk: {
                name: '',
                tags: [],
                description: '',
                claimUrl: '',
                claimType: '',
                claimEmail: '',
                claimCode: '',
            }
        };
    },
    props: {
        initialPerk: {
            type: Object,
            required: false
        }
    },
    beforeMount() {
        this.perk = this.initialPerk
            ? this.initialPerk
            : this.perk;

    },
    methods: {
        save() {
            if(this.perk.id) {
                this.updatePerk();
            } else {
                this.publish();
            }
        },
        dismiss (value) {
            this.$ionic.modalController.dismiss(value);
        },
        async updatePerk() {
            let loadingController = await this.$ionic.loadingController.create({message:'Please wait...'});
            loadingController.present();

            await apolloClient.mutate({
                mutation: UpdatePerk,
                variables: {
                    id: this.perk.id,
                    name: this.perk.name,
                    tags: "{" + this.perk.tags.join() + "}",
                    description: this.perk.description,
                    claimUrl: this.perk.claimUrl,
                    claimType: this.perk.claimType.toUpperCase(),
                    claimEmail: this.perk.claimEmail,
                    claimCode: this.perk.claimCode
                },
                update: (cache, { data }) => {
                    this.updatePerkByIdCache(cache, data);
                }
            });
            loadingController.dismiss();
            this.dismiss(this.perk);
        },
        async publish () {
            let loadingController = await this.$ionic.loadingController.create({message:'Please wait...'});
            loadingController.present();

            await apolloClient.mutate({
                mutation: InsertPerk,
                variables:  {
                    name: this.perk.name,
                    tags: "{" + this.perk.tags.join() + "}",
                    description: this.perk.description,
                    claimUrl: this.perk.claimUrl,
                    claimType: this.perk.claimType.toUpperCase(),
                    claimEmail: this.perk.claimEmail,
                    claimCode: this.perk.claimCode,
                    contactPersonId: $auth.user().id,
                    companyId: $auth.user().company.id
                    },
                 update: (cache, { data }) => {
                    this.updatePerkListCache(cache, data);
                }
            });

            loadingController.dismiss();
            this.dismiss(true);

        },
        updatePerkListCache(cache, data) {
            let { perks } = cache.readQuery({ query: ListPerks })

            perks.push(data.insert_Perk.returning[0]);

            cache.writeQuery({
                query: ListPerks,
                data: {
                    'perks': perks
                }
            });
        },
        updatePerkByIdCache(cache, data) {
            let { perk } = cache.readQuery({ query: GetPerkDetail, variables: { id: this.perk.id } });

            let perk_updated = data.update_Perk.returning[0];

            perk.name = perk_updated.name;
            perk.tags = perk_updated.tags;
            perk.description = perk_updated.description;
            perk.claimUrl = perk_updated.claimUrl;
            perk.claimType = perk_updated.claimType.toUpperCase();
            perk.claimEmail = perk_updated.claimEmail;
            perk.claimCode = perk_updated.claimCode;

            cache.writeQuery({
                    query: GetPerkDetail,
                    variable: {id: perk_updated.id},
                    data: {
                        'Perk_by_pk': perk_updated
                    }
                });

        }
    }
}
</script>
