<template>
    <div class="ion-page">
        <app-header title="Developer Tools" />
        <ion-content >
            <ion-button expand="block" color="primary" @click="toggleServer">{{toggleLabel}}</ion-button>
            <ion-button expand="block" color="light" @click="restart">Restart App</ion-button>
        </ion-content>
    </div>
</template>

<script>
import AppHeader from '@/components/global/AppHeader';
import $auth from '@/services/auth';

export default {
    components: {
        AppHeader
    },
    methods: {
        restart () {
            window.location.href = "index.html";
        },
        toggleServer () {
            if(!window.cordova){
                alert('This feature only works on installed apps');
            }
            else if (window.location.port == "8811"){
                const server = prompt("Server?");
                cordova.plugins.pushUpdate.useDebugServer(server);
            }
            else{
                cordova.plugins.pushUpdate.usePublicServer();
            }
        }
    },
    computed: {
        toggleLabel () {
            return (window.location.port == "8811") ? "Use Debug Server" : "Use Public Server";
        }
    }
}
</script>
