import { render, staticRenderFns } from "./AdvisorDetail.vue?vue&type=template&id=3f1995f2&scoped=true&"
import script from "./AdvisorDetail.vue?vue&type=script&lang=js&"
export * from "./AdvisorDetail.vue?vue&type=script&lang=js&"
import style0 from "./AdvisorDetail.vue?vue&type=style&index=0&id=3f1995f2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f1995f2",
  null
  
)

export default component.exports