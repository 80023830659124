<template>
    <div class="ion-page">
        <app-header title="Login"></app-header>
        <ion-content class="login">
            <ion-grid>
                <ion-row>
                    <!-- Before sending 2fa -->
                    <ion-col v-if="!sent2fa">
                        <p>Please enter your work email address below or tap the invitation link sent to you by email or SMS.</p>
                        <form class="form" @submit.prevent="sendCode">
                            <ion-item>
                                <ion-label position="stacked">Work email</ion-label>
                                <ion-input
                                    type="email"
                                    @input="setEmail($event.target.value)"
                                    :disabled="sent2fa"
                                    required
                                ></ion-input>
                                <ion-icon name="alert" v-if="email.length && !$v.email.email" class="error"></ion-icon>
                                <div v-else-if="email.length && $v.email.email">
                                    <ion-icon name="checkmark-circle" class="success"></ion-icon>
                                </div>
                            </ion-item>
                            <ion-button expand="block" color="primary" type="submit" :disabled="email.length && $v.email.$error">Continue</ion-button>
                            <p>Not a member yet? <a href="https://csuitecircle.typeform.com/to/XMmcaL" target="_blank">Request an invite</a></p>
                        </form>
                    </ion-col>

                    <!-- After sending 2fa -->
                    <ion-col v-if="sent2fa">
                        <p v-if="resent2fa">The code has been resent to <strong>{{ email }}</strong>.</p>
                        <p v-else>Please enter the login code sent to <strong>{{ email }}</strong>.</p>
                        <form class="form" @submit.prevent="login">
                            <ion-item>
                                <ion-label position="stacked">Authentication Code</ion-label>
                                <ion-input type="number" :value="code" @input="e => code = e.target.value" required></ion-input>
                            </ion-item>
                            <ion-button color="primary" type="submit" expand="block">
                                Login
                            </ion-button>
                            <ion-button color="secondary" @click="resendCode" fill="outline" expand="block">
                                Resend Code
                            </ion-button>
                        </form>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-content>
    </div>
</template>

<script>
import { InsertOTP, InsertToken } from '@/gql/login/Login.gql';
import ionicLoadingController from '@/mixins/ionicLoadingController';
import ionicAlertController from '@/mixins/ionicAlertController';
import { required, email } from "vuelidate/lib/validators";
import AppHeader from '@/components/global/AppHeader';

export default {
    name: 'login',

    components: {
        AppHeader
    },

    mixins: [ionicLoadingController, ionicAlertController],

    data() {
        return {
            email: '',
            code: '',
            sent2fa: false,
            resent2fa: false,
        }
    },

    validations: {
        email: { required, email }
    },

    methods:{
        setEmail(value) {
            this.email = value
            this.$v.email.$touch()
        },

        async sendCode() {
            this.presentLoading();
            try {
                let otp = await this.$apollo.mutate({
                    mutation:InsertOTP,
                    variables:{
                        email:this.email
                    }
                });
                this.dismissLoading();
                this.sent2fa = true;
            }
            catch (err) {
                this.dismissLoading();
                this.presentAlert('Error', err);
            }
        },

        async login() {
            this.presentLoading();
            try {
                let tokenResponse = await this.$apollo.mutate({
                    mutation: InsertToken,
                    variables:{
                        email: this.email,
                        code: this.code
                    }
                });
                const token = tokenResponse.data.insert_Token;
                localStorage.token = token;
                this.dismissLoading();
                this.presentAlert('Success', 'You have logged in successfully.');
                this.$router.push({name: "EnableNotifications"});
            }
            catch (err) {
                this.dismissLoading();
                this.presentAlert('Error', err);
            }
        },

        resendCode() {
            this.resent2fa = true;
            this.sendCode();
        },
    }
}
</script>

<style scoped>
    .link {
        color: #ffffff;
    }
    .link ion-icon {
        width: 25px;
        height: 22px;
        margin-bottom: -3px;
    }
    .login ion-button {
        margin-top: 20px;
    }

    .login ion-icon {
        font-size: 30px;
        width: 32px;
        height: 32px;
        position: absolute;
        right: 0;
        top: 27px;
    }

    .success {
        color: green;
    }
    .error {
        color: red;
    }
</style>
