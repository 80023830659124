<template>
  <div class="ion-page">
        <app-header title="Home" />
        <ion-content class="home">
            <ion-fab vertical="bottom" horizontal="end" slot="fixed">
              <ion-fab-button @click="showPostComposer()" color="primary">
                  <ion-icon name="create"></ion-icon>
              </ion-fab-button>
            </ion-fab>
            <ion-item lines="none">
                <ion-label class="ion-text-wrap">
                    <p>{{greeting}} {{ user.firstName }}! Welcome to C-Suite Circle.</p>
                </ion-label>
            </ion-item>
            <post-card v-for="post in posts" :key="post.id" :post="post"></post-card>
        </ion-content>
  </div>
</template>

<script>
import AppHeader from '@/components/global/AppHeader';
import PostCard from '@/components/feed/PostCard';
import $auth from '@/services/auth.js';
import PostComposer from '@/components/feed/PostComposer.vue';
import ListPosts from '@/gql/feed/ListPosts.gql';

export default {
    name: 'home',

    components: {
        AppHeader,
        PostCard
    },

    data() {
        return {
            loggedIn: false,
            user: $auth.user()
        }
    },

    computed : {
        greeting () {
            return 'Good afternoon'
        }
    },

    apollo: {
        posts: {
            query: ListPosts,
            variables() {
                return {
                    currentUserId: this.user.id
                }
            }
        }
    },

    methods: {
        async showPostComposer () {
            let modal = await this.$ionic.modalController.create({
                component: PostComposer
            })
            modal.onDidDismiss().then((data)=>{
                if (data) this.$apollo.queries.posts.refetch()
            })
            await modal.present()
        }
    }
}
</script>
