<template>
<ion-item lines="none" @click="openLink">
	<ion-thumbnail v-if="image" slot="start">
		<img :src="image" />
	</ion-thumbnail>
	<ion-label class="ion-text-wrap">
		<h2>{{title}}</h2>
		<p>{{description}}</p>
		<div><small>{{link}}</small></div>
	</ion-label>
</ion-item>
</template>
<script>
export default {
	props: {
		description: String,
		image: String,
		link: String,
		title: String,
	},
	methods: {
		openLink() {
			// TODO: make sure it's integrated with opening on mobile browsers properly
			window.open(this.link, '_system');
		}
	}
}
</script>
<style scoped>
ion-item ion-label p {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

ion-item ion-label small {
	text-overflow: ellipsis;
	white-space: nowrap;
}
</style>
