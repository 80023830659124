<template>
    <ion-page>
        <app-header title="New message" />
        <ion-content>
            <member-input @selectedMember="selectedMember" />
        </ion-content>
        <ion-footer>
            <conversation-composer @send="sendMessage" :disabled="!recipient"></conversation-composer>
        </ion-footer>
    </ion-page>
</template>

<script>
import $auth from '@/services/auth.js';
import InsertConversation from '@/gql/conversations/InsertConversation.gql';
import UpdateMessageCredit from '@/gql/members/UpdateMessageCredit.gql';
import GetConnectionStatus from '@/gql/connection/GetConnectionStatus.gql';
import GetConversation from '@/gql/conversations/GetConversation.gql';

import AppHeader from '@/components/global/AppHeader';
import ConversationComposer from '@/components/conversations/ConversationComposer';
import MemberInput from '@/components/conversations/MemberInput';

export default {
    name: 'conversation-create',

    components: {
        AppHeader,
        ConversationComposer,
        MemberInput,
    },

    data() {
        return {
            user: $auth.user(),
            recipient: null,
            connectionStatus: null,
        };
    },

    async beforeRouteEnter(to, from, next) {
        if (to.query.user) {
            next(vm => {
                vm.checkIfConversationExist(to.query.user)
            });
        }
        next();
    },

    methods: {
        async checkIfConversationExist(memberId) {
            let conversation = await this.$apollo.query({
                query: GetConversation,
                variables: {
                    user: $auth.user().id,
                    friend: memberId,
                },
                fetchPolicy: 'network-only',
            }).then(({ data }) => {
                return data.conversation[0];
            }).catch(err => console.log(err));

            if (conversation) {
                this.$router.push({
                    name: 'ConversationDetail',
                    params: { id: conversation.id }
                });
            }
        },

        selectedMember(member) {
            this.recipient = member;

            if(this.recipient) this.checkIfConversationExist(this.recipient.id);
        },

        async getConnectionStatus() {
            return await this.$apollo.query({
                query: GetConnectionStatus,
                variables: {
                    fromPersonId: this.user.id,
                    toPersonId: this.recipient.id
                },
            }).then(({ data }) => {
                return data.connection[0] && data.connection[0].status;
            }).catch(err => console.log(err));
        },

        async sendMessage(conversationMsg) {
            this.connectionStatus = await this.getConnectionStatus();

            if (this.connectionStatus != 'APPROVED' && $auth.user().messageCredits < 1) {
                await this.$ionic.alertController.create({
                    header: 'Insufficient credits',
                    buttons: [{ text: 'Cancel', role: 'cancel' }]
                }).then(a => a.present());
                return
            } 

            await this.$apollo.mutate({
                mutation: InsertConversation,
                variables: {
                    currentUserId: this.user.id,
                    recipientId: this.recipient.id,
                    message: (conversationMsg.message) ? conversationMsg.message: '',
                    attachmentName: conversationMsg.attachmentName,
                    attachmentType: conversationMsg.attachmentType,
                    attachmentSrc: conversationMsg.attachmentSrc
                }
            }).then(({ data }) => {
                if(this.connectionStatus != 'APPROVED') this.updateMessageCredit();

                this.$router.push({
                    name: 'ConversationDetail',
                    params: {
                        id: data.insert_Conversation.returning[0].id
                    }
                });
            }).catch(err => console.log(err));
        },

        async updateMessageCredit() {
            await this.$apollo.mutate({
                mutation: UpdateMessageCredit,
                variables: {
                    id: $auth.user().id,
                    messageCredits: this.user.messageCredits - 1,
                },
                update: async (client, { data: { update_Person } }) => {
                    this.user = update_Person.returning[0];
                },
            }).catch(err => console.log(err));
        },
    },
};
</script>
