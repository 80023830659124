<template>
    <ion-page>
        <app-header title="Member Profile" />
        <ion-content>
            <ion-spinner v-if="$apollo.queries.memberDetail.loading" name="bubbles" class="spinner"></ion-spinner>
            <div v-else>
                <ion-card>
                    <ion-card-header>
                        <div class="avatar" v-if="memberDetail.picture">
                            <img :src="memberDetail.picture" alt="">
                        </div>
                        <div class="ion-text-center">
                            <h3>{{ memberDetail.name }}</h3>
                            <h5 class="country">
                                {{ memberDetail.title }} <span v-if="memberDetail.companyName">at {{  memberDetail.companyName }}</span>
                            </h5>
                            <h5 class="country">{{ memberDetail.location }}</h5>
                        </div>
                    </ion-card-header>
                    <ion-card-content class="card-content">
                        <connection-btn v-if="connectionStatus !== 'DECLINED'" :member="memberDetail" />

                        <ion-button color="secondary" @click="createConversation">
                            <ion-icon name="chatbubble"></ion-icon>Send a message
                        </ion-button>

                        <introduction-request-btn :member-detail="memberDetail" />

                        <div class="social-media-links">
                            <ion-button :href="memberDetail.linkedinUrl" rel="external" target="_blank" class="linkedIn">
                                <ion-icon ios="ios-logo-linkedin" md="md-logo-linkedin"></ion-icon>
                            </ion-button>
                            <ion-button v-if="memberDetail.twitterAccountId" color="secondary" :href="`https://twitter.com/${memberDetail.twitterAccountId}`" rel="external" target="_blank">
                                <ion-icon ios="ios-logo-twitter" md="md-logo-twitter"></ion-icon>
                            </ion-button>
                            <ion-button v-if="memberDetail.mediumAccountId" color="dark" :href="`https://medium.com/@${memberDetail.mediumAccountId}`" rel="external" target="_blank">
                                <img src="https://img.icons8.com/ios-glyphs/30/ffffff/medium-monogram.png"/>
                            </ion-button>
                        </div>
                    </ion-card-content>
                </ion-card>
                <post-card v-for="post in memberDetail.posts" :key="post.id" :post="post"></post-card>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import $auth from '@/services/auth.js';
import $notifications from '@/services/notifications.js';
import getMemberDetail from '@/gql/members/GetMemberDetail.gql';
import GetConversation from '@/gql/conversations/GetConversation.gql';

import AppHeader from '@/components/global/AppHeader';
import ConnectionBtn from '@/components/connections/ConnectionBtn.vue';
import IntroductionRequestBtn from '@/components/introduction/IntroductionRequestBtn.vue';
import PostCard from '@/components/feed/PostCard';
import connectionMixin from '@/mixins/connectionMixin';

export default {
    components: {
        AppHeader,
        ConnectionBtn,
        IntroductionRequestBtn,
        PostCard,
    },

    mixins: [ connectionMixin ],

    apollo: {
        memberDetail: {
            query: getMemberDetail,
            variables() {
                return {
                    id: this.$route.params.id,
                };
            }
        },
    },

    data() {
        return {
            memberDetail: null,
        };
    },

    mounted() {
        this.viewedProfile();
    },

    methods: {
         async getConversation(user, friend) {
            let conversation = await this.$apollo.query({
                query: GetConversation,
                variables: {
                    user: user,
                    friend: friend,
                },
                fetchPolicy: 'network-only',
            }).then(({ data }) => {
                return data.conversation[0];
            }).catch(err => console.log(err));
        },

        async createConversation() {
            let conversation = await this.getConversation($auth.user().id, this.memberDetail.id);

            if (conversation) {
                this.$router.push({
                    name: 'ConversationDetail',
                    params: { id: conversation.id }
                });
            } else if (this.isConnected || this.hasMessageCredits){
                this.$router.push({
                    name: 'ConversationCreate',
                    query: {
                        user: this.memberDetail.id
                    }
                });
            } else {
                this.$ionic.alertController.create({
                    header: 'Insufficient credits',
                    buttons: [{ text: 'Cancel', role: 'cancel' }]
                }).then(a => a.present());
            }
        },

        viewedProfile() {
            const view = this.$route.name;
            const id = this.$route.params.id;
            setTimeout( ()=> {
                const currentRoute = this.$router.currentRoute;
                if ($auth.user().id === id) return;
                if (currentRoute.name !== view || currentRoute.params.id !== id) return;
                $notifications.send({
                    recipientId: id,
                    typeId: "VIEWED_PROFILE",
                });
            },5000)
        }
    },
}
</script>

<style scoped>
    .spinner {
        display: block;
        margin: 10px auto;
    }
    .avatar {
        max-width: 250px;
        margin: 0 auto;
    }
    .card-content {
        padding-top: 0;
        text-align: center;
    }
    .avatar img {
        background: #f1f1f1;
    }
    ion-icon[name="chatbubble"],
    .connection ion-icon {
        margin-right: 7px;
    }
    .social-media-links {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
    .social-media-links ion-button.linkedIn {
        --background-activated: #0e76a8;
        --background: #0e76a8;
    }
    .social-media-links ion-button {
        margin-right: 10px;
    }
</style>
