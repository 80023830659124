<template>
    <ion-item class="chat fade">
        <ion-avatar slot="start" v-if="conversation.author">
            <img :src="conversation.author.picture">
        </ion-avatar>
        <ion-label class="message">
            <h2>
                <strong>{{ conversation.author.name }}</strong>
                <time><span class="dot"></span>{{ conversation.createdAt | moment('from') }}</time>
            </h2>
            <p>{{ conversation.message }}</p>
            <div v-if="conversation.attachmentType" class="media">
                <img v-if="conversation.attachmentType.includes('image/')" :src="conversation.attachmentSrc" alt="">
                <video controls v-else-if="conversation.attachmentType.includes('video/')" :src="conversation.attachmentSrc" alt=""></video>
                <audio controls v-else-if="conversation.attachmentType.includes('audio/')" :src="conversation.attachmentSrc" alt=""></audio>
                <post-file v-else target="_blank" :post="conversation"></post-file>
            </div>
        </ion-label>
        <ion-button slot="end" @click="presentPopover($event)" fill="clear" size="small" color="dark" class="ion-no-padding" style="height: 20px;">
            <ion-icon slot="icon-only" name="ellipsis-horizontal-outline"></ion-icon>
        </ion-button>
    </ion-item>
</template>

<script>
import PostFile from '@/components/feed/PostFile';
import MessageActionPopover from './MessageActionPopover';

export default {
    name: 'conversation-message-card',

    components: {
        PostFile
    },
    
    props: {
        conversation: {
            type: Object,
            default() {
                return {};
            }
        },
    },

    computed: {
        messageRecipient() {
            return this.conversation.conversationMessageRecipients[0];
        }
    },

    methods: {
        async presentPopover(event) {
            const popover = await this.$ionic.popoverController.create({
                component: MessageActionPopover,
                componentProps: {
                    data: {
                        author: this.conversation.author,
                    },
                },
                event: event,
                translucent: true,
            });

            popover.onDidDismiss().then(({ data })=>{
                if(data && (data.deleteForMe || data.deleteForEveryone)) {
                    this.presentAlertDialog(data);
                }
            });

            await popover.present();
        },

        presentAlertDialog(data) {
            this.$ionic.alertController.create({
                header: `Are you sure you want to delete this message ${ data.deleteForEveryone ? 'for everyone': '' }?`,
                buttons: [
                    { text: 'Cancel', role: 'cancel'},
                    {
                        text: 'Yes, delete',
                        handler: () => {
                            if (data.deleteForMe)  this.$emit('deleteMessageForMe', this.messageRecipient);
                            if (data.deleteForEveryone) this.$emit('deleteMessageForEveryone', this.messageRecipient);
                        }
                    }
                ]
            }).then(a => a.present());
        }
    }
};
</script>

<style scoped>
.chat {
    --inner-border-width: 0px 0px 0.44px 0px;
    --ion-item-border-color: #eaeaea;
}
ion-avatar {
    align-self: flex-start;
    margin-top: 12px;
}
.message {
    white-space: normal;
}
.message time {
    font-size: 13px;
    color: #cbc8c8;
}
.message time span {
    display: inline-block;
    width: 5px;
    height: 5px;
    background-color: #cbc8c8;
    border-radius: 50%;
    margin: 0 5px;
    vertical-align: middle;
}
.message p {
    padding: 4px 0 7px;
    color: #000000;
}
.fade {
    animation-delay: 0s;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    animation-name: fadeChatIn;
}

@keyframes fadeChatIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
.media {
    width: 300px;
    height: 200px;
    overflow: hidden;
}
.media img,
.media video,
.media audio {
    max-width: 100%;
    max-height: 200px;
}
</style>