<template>
    <ion-page>
        <app-header title="C-Suite Directory">
            <ion-button v-router-link="{ name: 'ConnectionList' }" fill="clear">
                <ion-label>My network</ion-label>
            </ion-button>
        </app-header>
        <ion-content>
            <ion-refresher slot="fixed" id="refresher" @ionRefresh="refresh($event)">
                <ion-refresher-content></ion-refresher-content>
            </ion-refresher>
            <ion-spinner v-if="$apollo.loading" name="bubbles" class="spinner"></ion-spinner>
            <ion-list>
                <member-card v-for="member in members" :key="member.id" v-router-link="{ name: 'MemberDetail', params: { id: member.id }}" :member="member" />
            </ion-list>
            <infinite-scroll @fetchMore="fetchMore" />
        </ion-content>
    </ion-page>
</template>

<script>
import listMembers from '@/gql/members/ListMembers.gql';
import AppHeader from '@/components/global/AppHeader';
import MemberCard from '@/components/cards/MemberCard';
import InfiniteScroll from '@/components/global/InfiniteScroll';

export default {
    components: {
        AppHeader,
        InfiniteScroll,
        MemberCard,
    },

    apollo: {
        members: {
            query: listMembers,
            variables() {
                return {
                    limit: this.perPage,
                    offset: 0,
                }
            },
            watchQuery: {
                fetchPolicy: 'cache-and-network'
            },
            fetchPolicy: 'cache-and-network',
        },
    },

    data() {
        return {
            members: null,
            offset: 0,
            page: 0,
            perPage: 12,
        };
    },

    methods: {
        async refresh(event) {
            this.page = 0;
            this.offset = 0;
            await this.$apollo.queries.members.refetch();
            event.target.complete();
        },

        async fetchMore(event) {
            try {
                this.page++;
                this.offset = this.page * this.perPage;
                await this.$apollo.queries.members.fetchMore({
                    variables: {
                        limit: this.perPage,
                        offset: this.offset,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        if (!fetchMoreResult) {
                            return previousResult;
                        }
                        return Object.assign({}, previousResult, {
                            members: [ ...previousResult.members, ...fetchMoreResult.members]
                        });
                    },
                });
            } catch(err) {
                console.log(err);
            }
            event.target.complete();
        },
    }
};
</script>
<style scoped>
    .spinner {
        display: block;
        margin: 10px auto;
    }
</style>
