<template>
    <ion-item>
        <ion-label position="stacked">{{ label }}</ion-label>
        <ion-datetime 
            :value="time"
            @ionChange="setInput($event.target.value)"
            display-format="HH:mm" 
            display-timezone="utc"
        ></ion-datetime>
    </ion-item>
</template>

<script>
export default {
    name: 'time-input',

    props: {
        label: {
            type: String,
            default: 'Time',
        },
        value: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            time: this.value,
        };
    },

    methods: {
        setInput(val) {
            this.time = val;
            this.$emit('input', this.time);
        }
    },
}
</script>