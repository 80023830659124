<template>
<div>
	<ion-header>
		<ion-toolbar>
			<ion-buttons slot="start">
				<ion-button @click="dismiss()">
					<ion-icon slot="icon-only" name="close"></ion-icon>
				</ion-button>
			</ion-buttons>
			<ion-title>Edit Profile</ion-title>
			<ion-buttons slot="end">
				<ion-button @click="save()">Save</ion-button>
			</ion-buttons>
		</ion-toolbar>
	</ion-header>
	<ion-content>
		<ion-list>
			<image-input v-model="memberDetail.picture"></image-input>
			<ion-item>
				<ion-label position="stacked">LinkedIn</ion-label>
				<ion-input placeholder="https://www.linkedin.com/in/reidhoffman" inputmode="url" type="url" :value="memberDetail.linkedinUrl" @input="memberDetail.linkedinUrl = $event.target.value"></ion-input>
			</ion-item>
			<ion-item>
				<ion-label position="stacked">Twitter</ion-label>
				<ion-input placeholder="jack" inputmode="text" type="text" :value="memberDetail.twitterAccountId" @input="memberDetail.twitterAccountId = $event.target.value"></ion-input>
			</ion-item>
			<ion-item>
				<ion-label position="stacked">Medium</ion-label>
				<ion-input placeholder="ev" inputmode="text" type="text" :value="memberDetail.mediumAccountId" @input="memberDetail.mediumAccountId = $event.target.value"></ion-input>
			</ion-item>
            <ion-item>
                <ion-label class="ion-text-wrap">
                    <p>
                        To edit other fields on your profile, please contact
                        <a href="mailto:hello@csuitecircle.com">hello@csuitecircle.com</a>
                    </p>
                </ion-label>
            </ion-item>
		</ion-list>
	</ion-content>
</div>
</template>

<script>
import ImageInput from '@/components/inputs/ImageInput';
import {
	apolloClient
} from '@/services/apollo';
import UpdateProfile from '@/gql/profile/UpdateProfile.gql';

export default {
	name: 'ProfileEditor',
	components: {
		ImageInput,
	},
	data() {
		return {
			memberDetail: null,
		}
	},
	methods: {
		dismiss() {
			this.$ionic.modalController.dismiss(false);
		},
		async save() {
			const loadingController = await this.$ionic.loadingController.create({
				message: 'Please wait...'
			});
			loadingController.present();
			const profile = await apolloClient.mutate({
				mutation: UpdateProfile,
				variables: {
					id: this.memberDetail.id,
					picture: this.memberDetail.picture,
					linkedinUrl: this.memberDetail.linkedinUrl,
					twitterAccountId: this.memberDetail.twitterAccountId,
					mediumAccountId: this.memberDetail.mediumAccountId,
				}
			});
			loadingController.dismiss();
			this.$ionic.modalController.dismiss(true);
		},
	},
}
</script>
<style scoped>

</style>
