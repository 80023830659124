<template>
  <cs-conversation-item
    v-router-link="{ name: 'ConversationDetail', params: { id: conversation.id } }"
    :subject="participant.name"
    :picture="participant.picture"
    :role="participant.isConsultant ? 'C-Suite Advisor' : ''"
    :message="conversationMessages.message"
    :unread="unreadCount"
    :date="conversationMessages.createdAt"
  ></cs-conversation-item>
</template>

<script>
import $auth from '@/services/auth.js';

export default {
    name: 'conversation-card',

    props: {
        conversation: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            user: $auth.user(),
        };
    },

    computed: {
        participant() {
            return this.conversation &&
                this.conversation.conversationParticipants &&
                this.conversation.conversationParticipants[0].participantPerson;
        },

        conversationMessages() {
            return this.conversation &&
                this.conversation.conversationMessages &&
                this.conversation.conversationMessages[0];
        },

        unreadCount() {
          const unread = this.conversation &&
              this.conversation.unread &&
              this.conversation.unread.aggregate.count;
          if (unread > 50) {
            return "50+";
          }
          return unread;

        }
    },
}
</script>

<style scoped>
.conversation-list-item {
    position: relative;
}
ion-avatar {
    width: 45px;
    height: 45px;
    background: #f1f1f1;
}
.icon-create {
    font-size: 27px;
}
h2 span{
    width: 160px;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
}
h2 time {
    float: right;
    font-size: 13px;
    color: #666464;
}
.center {
    margin: 0 auto;
    display: block;
}
</style>
