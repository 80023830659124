<template>
    <div>
         <ion-list class="member-list">
            <div class="input-container">
                <ion-chip v-if="recipient" color="primary" outline>
                    <ion-avatar>
                        <img :src="recipient.picture">
                    </ion-avatar>
                    <ion-label>{{ recipient.name }}</ion-label>
                    <ion-icon name="close-circle" @click="removeSelectedMember"></ion-icon>
                </ion-chip>
                <ion-searchbar v-else :value="query" @ionInput="setInput($event.target.value)" placeholder="Type a name"></ion-searchbar>
            </div>
            <ion-spinner v-if="$apollo.loading" name="lines"></ion-spinner>
            <div v-else-if="query && members && members.length < 1" class="empty-list">
                <ion-icon name="alert-circle"></ion-icon>
                <p>No member with that name.</p>
            </div>
            <ion-item v-for="member in members" :key="member.id" @click="selectedMember(member)" class="member">
                <member-card :member="member" lines="none"/>
            </ion-item>
        </ion-list>
        <infinite-scroll @fetchMore="fetchMoreMembers" />
    </div>
</template>

<script>
import ListMembers from '@/gql/members/ListMembers.gql';
import getMemberDetail from '@/gql/members/GetMemberDetail.gql';

import MemberCard from '@/components/cards/MemberCard';
import InfiniteScroll from '@/components/global/InfiniteScroll';

export default {
    name: 'member-input',

    components: {
        MemberCard,
        InfiniteScroll,
    },

    apollo: {
        members: {
            query: ListMembers,
            variables() {
                return {
                    limit: this.perPage,
                    offset: 0,
                    name: `${this.query}%`,
                }
            },
            skip() {
                return this.skipQuery
            },
        },
    },

    data() {
        return {
            members: null,
            skipQuery: true,
            query: null,
            recipient: null,
            offset: 0,
            perPage: 10,
        };
    },

    created() {
        if(this.$route.query.user) {
            this.getMemberDetail();
        }
    },

    methods: {
        async setInput(val) {
            if(val.length) {
                this.query = val.trim();
                this.$apollo.queries.members.skip = false;
                this.offset = 0;
                await this.$apollo.queries.members.refetch();
            } else {
                this.query = null;
                this.members = null;
            }
        },

        async fetchMoreMembers(event) { 
            try { 
                this.offset = this.offset + this.perPage;
                await this.$apollo.queries.members.fetchMore({
                    variables: {
                        limit: this.perPage,
                        offset: this.offset,
                        name: `${this.query}%`,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        if (!fetchMoreResult) {
                            return previousResult;
                        }
                        return Object.assign({}, previousResult, {
                            members: [ ...previousResult.members, ...fetchMoreResult.members]
                        });
                    },
                });
            } catch(err) {
                console.log(err);
            }
           
            event.target.complete();
        },

        selectedMember(member) {
            this.recipient = member;
            this.query = null;
            this.members = null;
            this.$emit('selectedMember', this.recipient);
        },

        removeSelectedMember() {
            this.recipient = null;
            this.$emit('selectedMember', this.recipient);
        },

        async getMemberDetail() {
            await this.$apollo.addSmartQuery('memberDetail', {
                query: getMemberDetail,
                variables() {
                    return {
                        id: this.$route.query.user
                    };
                },
                result({ data }) {
                    this.selectedMember(data.memberDetail);
                }
            });
        },
    },

}
</script>

<style scoped>
.input-container {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 2;
    background-color: #ffffff;
    border-bottom: 1px solid #ededed;
    text-align: left;
}
.member-list {
    border: 1px solid #cdcfd2;
    border-radius: 5px;
    text-align: center;
    /* margin-top: 43px; */
}

.empty-list {
    display: flex;
    align-content: center;
    align-items: center;
    font-size: 15px;
    color: #0942a4;
}

.empty-list ion-icon {
    font-size: 25px;
    margin-right: 4px;
}

.member-list .member:hover {
    --ion-item-background: #e3f2ff;
}

.member {
    --inner-border-width: 0px 0px 0.44px 0px;
    --ion-item-border-color: #eaeaea;
}
</style>