import {apolloClient} from '@/services/apollo';
import { getUserDetail } from '@/gql/login/Login.gql';

var currentUser;

function hasValidToken(){
    return (!!localStorage.token);
}

function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
};

async function login() {
    if(localStorage.token){
        let jwt = parseJwt(localStorage.token);
        let userData = await apolloClient.query({
            query: getUserDetail,
            variables: { id:jwt.sub }
        })
        currentUser = userData.data.userDetail;
    }
}

function logout(){
    if(confirm("Are you sure you wish to logout?")){
        localStorage.removeItem('token');
        window.location.href = '/';
    }
}

function getCurrentUser () {
    return currentUser
}

export default {
    user : getCurrentUser,
    hasValidToken: hasValidToken,
    login: login,
    logout: logout
}
