<template>
<ion-page>
	<app-header title="Notifications">
		<ion-button v-router-link="{name: 'NotificationSettings'}">
			<ion-icon slot="icon-only" name="cog"></ion-icon>
		</ion-button>
	</app-header>
	<ion-content>
        <ion-refresher slot="fixed" id="refresher" @ionRefresh="refresh($event)">
            <ion-refresher-content></ion-refresher-content>
        </ion-refresher>
		<ion-list>
			<ion-item v-for="(notification, $index) in processedNotifications" :key="notification.id" @click="openNotification(notification)">
				<ion-avatar slot="start">
					<img :src="notification.actor.picture">
				</ion-avatar>
				<ion-label class="ion-text-wrap">
					<h3 :class="{'unseen': !notification.seenAt}">
						{{notification.title}}
					</h3>
				</ion-label>
                <ion-text v-if="!notification.openedAt" class="unopened" color="secondary" slot="end">&#9679;</ion-text>
			</ion-item>
			<p v-if="notifications && notifications.length == 0" class="ion-text-wrap ion-padding ion-text-center">
				No notifications yet
			</p>
		</ion-list>
        <infinite-scroll @fetchMore="fetchMore" />
	</ion-content>
</ion-page>
</template>

<script>
import ListNotifications from '@/gql/notifications/ListNotifications.gql';
import BatchMarkSeen from '@/gql/notifications/BatchUpdateSeen.gql';
import MarkOpened from '@/gql/notifications/UpdateOpened.gql';
import AppHeader from '@/components/global/AppHeader';
import InfiniteScroll from '@/components/global/InfiniteScroll';
import $auth from '@/services/auth';

export default {
	components: {
		AppHeader,
        InfiniteScroll,
	},
	apollo: {
		notifications: {
			query: ListNotifications,
			variables() {
				return {
					limit: this.perPage,
					offset: 0,
					recipientId: $auth.user().id
				}
			},
            watchQuery: {
                fetchPolicy: 'cache-and-network'
            },
            fetchPolicy: 'cache-and-network',
			result({
				data,
				loading
			}) {
				if (!loading) {
					this.generateNotificationTitles();
					this.markSeen();
				}
			},
		},
	},
	data() {
		return {
            notifications: null,
			processedNotifications: null,
            offset: 0,
            page: 0,
            perPage: 20,
		}
	},
	methods: {
        async refresh(event) {
            this.page = 0;
            this.offset = 0;
            await this.$apollo.queries.notifications.refetch();
            event.target.complete();
        },
        async fetchMore(event) {
            this.page++;
            this.offset = this.page * this.perPage;
            await this.$apollo.queries.notifications.fetchMore({
                variables: {
                    limit: this.perPage,
                    offset: this.offset,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    if (!fetchMoreResult) {
                        return previousResult;
                    }
                    return Object.assign({}, previousResult, {
                        notifications: [ ...previousResult.notifications, ...fetchMoreResult.notifications]
                    });
                },
            });
            event.target.complete();
        },

		openNotification(notification) {
			this.$apollo.mutate({
				mutation: MarkOpened,
				variables: {
					id: notification.id,
				}
			});
			switch (notification.type.id) {
				case "VIEWED_PROFILE":
					this.$router.push({
						name: "MemberDetail",
						params: {
							id: notification.actor.id
						}
					})
					break;
				case "LIKED_POST":
					this.$router.push({
						name: "PostDetail",
						params: {
							id: notification.post.id
						}
					})
					break;
				case "COMMENTED_POST":
					this.$router.push({
						name: "PostDetail",
						params: {
							id: notification.post.id
						}
					})
					break;
				case "ALSO_COMMENTED_POST":
					this.$router.push({
						name: "PostDetail",
						params: {
							id: notification.post.id
						}
					})
					break;
				default:
					//Nothing to do
			}
		},
		async markSeen() {
			const unseen = this.notifications.filter(n => {
				return !n.seenAt;
			}).map(n => n.id)
            if (unseen.length < 1) return;
			await this.$apollo.mutate({
				mutation: BatchMarkSeen,
				variables: {
					notificationIds: unseen
				}
			});
			this.$parent.$emit("updateStatus",true)
		},
		generateNotificationTitles() {
			this.notifications.forEach(n => {
				if (!n.title) n.title = this.notificationTitle(n);
			});
			this.processedNotifications = this.notifications;
		},
		notificationTitle(notification) {
			let action = "";
			action = notification.type.template;
			action = action.split("{actor}").join(notification.actor.name);
			if (notification.target) action = action.split("{target}").join(notification.target.name);
			if (notification.event) action = action.split("{event}").join(notification.event.name);
			if (notification.job) action = action.split("{job}").join(notification.job.name);
			if (notification.perk) action = action.split("{perk}").join(notification.perk.name);
			return action;
		},
	},
}
</script>
<style media="screen">
ion-item ion-label .unseen {
	font-weight: bold;
}
</style>
