<template>
    <ion-page>
        <cs-subcategory-header v-if="participant">{{participant.name}}</cs-subcategory-header>
        <ion-content id="content">
             <ion-list class="list" v-if="conversationMessages">
                <div v-for="group in conversationMessages">
                  <div class="message-group-header">
                    <span class="header-bar bar-left"></span>
                    <span class="header-text">{{group.dateText}}</span>
                    <span class="header-bar bar-right"></span>
                  </div>
                  <cs-message
                      v-for="(message, index) in group.messages"
                      :key="message.id"
                      :variant="message.author.id === user.id ? 'outgoing' : 'incoming'"
                      :thumbnail="seriesFirstMessage(group.messages, index) ? message.author.picture : ''"
                      :name="seriesFirstMessage(group.messages, index) ? message.author.name : ''"
                      :date="seriesLastMessage(group.messages, index) ? message.createdAt : ''"
                  >
                      <div v-if="message.attachmentType" class="media">
                          <img v-if="message.attachmentType.includes('image/')" :src="message.attachmentSrc" alt="">
                          <video controls v-else-if="message.attachmentType.includes('video/')" :src="message.attachmentSrc" alt=""></video>
                          <audio controls v-else-if="message.attachmentType.includes('audio/')" :src="message.attachmentSrc" alt=""></audio>
                          <post-file v-else target="_blank" :post="message"></post-file>
                      </div>
                      {{message.message}}
                  </cs-message>
                </div>
            </ion-list>
        </ion-content>
        <ion-footer>
            <conversation-composer @send="sendMessage" :disabled="sending" />
        </ion-footer>
    </ion-page>
</template>

<script>
import $auth from '@/services/auth.js';
import ListConversationMessages from '@/gql/conversations/ListConversationMessages.gql';
import InsertConversationMessage from '@/gql/conversations/InsertConversationMessage.gql';
import ListConversationParticipant from '@/gql/conversations/ListConversationParticipant.gql';
import DeleteMessageForMe from '@/gql/conversations/DeleteMessageForMe.gql';
import DeleteMessageForEveryone from '@/gql/conversations/DeleteMessageForEveryone.gql';
import UpdateConversationLastRead from '@/gql/conversations/UpdateConversationLastRead.gql';

import ConversationComposer from '@/components/conversations/ConversationComposer';
import ConversationMessageCard from '@/components/conversations/ConversationMessageCard';

export default {
    name: 'conversation-detail',

    components: {
        ConversationComposer,
        ConversationMessageCard,
    },

    apollo: {
        $subscribe: {
            conversationMessages: {
                query: ListConversationMessages,
                variables() {
                    return {
                        conversationId: this.$route.params.id,
                        personId: this.user.id,
                    }
                },
                result({ data }) {
                    this.groupMessagesByDate(data.conversationMessages.slice().reverse());
                },
                fetchPolicy: 'cache-and-network',
            },
        },

        conversationParticipant: {
            query: ListConversationParticipant,
            variables() {
                return {
                    conversationId: this.$route.params.id,
                    personId: this.user.id,
                }
            },
        },
    },

    data() {
        return {
            conversationMessages: null,
            conversationParticipant: null,
            user: $auth.user(),
            sending: false,
        };
    },

    computed: {
        participant() {
            return this.conversationParticipant && this.conversationParticipant[0].participantPerson;
        },
    },

    updated() {
        this.scrollToBottom();
    },

    watch: {
        'conversationMessages.length'() {
            setTimeout(() => {
               this.scrollToBottom();
            }, 300);
        }
    },

    methods: {
        groupMessagesByDate(messages) {
            const groups = [];
            let group = {};
            messages.forEach(m => {
              const mdate = new Date(m.createdAt).toDateString();
              if (mdate != group.date) {
                group = {
                  date: new Date(m.createdAt).toDateString(),
                  messages: [m],
                };
                const today = new Date().toDateString();
                let yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                yesterday = yesterday.toDateString();
                if (group.date === today) {
                  group.dateText = "Today";
                } else if (group.date === yesterday) {
                  group.dateText = "Yesterday";
                } else {
                  group.dateText = new Date(m.createdAt).toDateString();
                }
                groups.push(group);
              } else {
                group.messages.push(m);
              }
            });
            this.conversationMessages = groups;
        },

        async sendMessage(conversationMsg) {
            try {
                this.sending = true;
                await this.$apollo.mutate({
                    mutation: InsertConversationMessage,
                    variables: {
                        conversationId: this.$route.params.id,
                        authorId: this.user.id,
                        recipientId: this.participant.id,
                        message: (conversationMsg.message) ? conversationMsg.message: '',
                        attachmentType: conversationMsg.attachmentType,
                        attachmentSrc: conversationMsg.attachmentSrc,
                        attachmentName: conversationMsg.attachmentName,
                    },
                    update: (cache, { data: { insert_ConversationMessage } }) => {
                        this.conversationMessages.unshift(insert_ConversationMessage.returning[0]);
                    },
                });
                this.sending = false;
            } catch(err) {
                this.sending = false;
            }
        },

        async deleteMessageForMe(messageRecipient) {
            this.conversationMessages.splice(this.conversationMessages.findIndex(c => c.id === messageRecipient.messageId),1);

            await this.$apollo.mutate({
                mutation: DeleteMessageForMe,
                variables: {
                    id: messageRecipient.id,
                },
            }).catch();
        },

        async deleteMessageForEveryone(messageRecipient) {
            this.conversationMessages.splice(this.conversationMessages.findIndex(c => c.id === messageRecipient.messageId),1);

            await this.$apollo.mutate({
                mutation: DeleteMessageForEveryone,
                variables: {
                    messageId: messageRecipient.messageId,
                },
            }).catch();
        },

        async markAllRead() {
          await this.$apollo.mutate({
              mutation: UpdateConversationLastRead,
              variables: {
                  conversationId: this.$route.params.id,
                  personId: this.user.id,
              },
          });
        },

        seriesFirstMessage(messages, index) {
            const message = messages[index];
            const prevMessage = messages[index-1];
            if (!message || (message && message.author.id === this.user.id)) {
              return false;
            } else if (!prevMessage) {
              return true;
            } else if (message.author.id === prevMessage.author.id) {
              return false;
            } else {
              return true;
            }
        },

        seriesLastMessage(messages, index) {
            const message = messages[index];
            const nextMessage = messages[index+1];
            if (!message) {
              return false;
            } else if (!nextMessage) {
              return true;
            } else if (message.author.id === nextMessage.author.id) {
              return false;
            } else {
              return true;
            }
        },

        scrollToBottom() {
            const contentContainer = document.getElementById('content');
            contentContainer.scrollToBottom();
        },
    },

    created() {
        this.markAllRead();
    }
}
</script>
<style scoped>
.list {
    margin-bottom: 0;
    padding: 16px;
}
.message-group-header {
  margin-top: 20px;
  margin-bottom: 24px;
  display: flex;
}
.message-group-header .header-text {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  color: var(--csuite-grey-03);
  padding: 0 9px;
}
.message-group-header .header-bar {
  flex: 1;
  height: 1px;
  background-color: #0f0;
  background-color: var(--csuite-grey-02);
  margin-top: 6px;
}
ion-footer {
  box-shadow: 0 -3px 14px rgba(0, 0, 0, 0.05);
}
</style>
