<template>
<div>
	<ion-header>
		<ion-toolbar>
			<ion-buttons slot="start">
				<ion-button @click="dismiss()">
					<ion-icon slot="icon-only" name="close"></ion-icon>
				</ion-button>
			</ion-buttons>
			<ion-title>{{event.id ? "Edit Event" : "Create Event"}}</ion-title>
			<ion-buttons slot="end">
				<ion-button @click="publish()" :disabled="!canPost">{{event.id ? "Save" : "Create"}}</ion-button>
			</ion-buttons>
		</ion-toolbar>
	</ion-header>
	<ion-content>
		<ion-list>
			<ion-item>
				<ion-label position="stacked">Name *</ion-label>
				<ion-input :value="event.name" @ionInput="event.name = $event.target.value" inputmode="text" type="text" required></ion-input>
			</ion-item>
			<image-input label="Picture" class="image-input" v-model="event.picture"></image-input>
			<ion-item>
				<ion-label position="stacked">Description</ion-label>
				<ion-textarea :value="event.description"></ion-textarea>
			</ion-item>
			<div class="date-time">
				<date-input v-model="start.date" label="Start Date *"></date-input>
				<time-input v-model="start.time" label="Time *"></time-input>
			</div>
			<div class="date-time">
				<date-input v-model="end.date" label="End Date *"></date-input>
				<time-input v-model="end.time" label="Time *"></time-input>
			</div>
			<ion-item>
				<ion-label position="stacked">Website</ion-label>
				<ion-input :value="event.website" @ionInput="event.website = $event.target.value" inputmode="url" type="url"></ion-input>
			</ion-item>
			<tag-input label="Tags" v-model="event.tags"></tag-input>
			<ion-item>
				<ion-checkbox slot="start" :checked="event.company && event.company.id === user.company.id" @ionChange="toggleCompany"></ion-checkbox>
				<ion-label>This event is hosted by {{user.company.name}}</ion-label>
			</ion-item>
		</ion-list>
	</ion-content>
</div>
</template>

<script>
import $auth from '@/services/auth';
import {
	apolloClient
} from '@/services/apollo';
import UpdateEvent from '@/gql/event/UpdateEvent.gql';
import InsertEvent from '@/gql/event/InsertEvent.gql';
import ImageInput from '@/components/inputs/ImageInput'
import DateInput from '@/components/inputs/DateInput'
import TimeInput from '@/components/inputs/TimeInput'
import TagInput from '@/components/inputs/TagInput'

export default {
	name: 'EventEditor',
	components: {
		ImageInput,
		DateInput,
		TimeInput,
		TagInput,
	},
	data() {
		return {
			event: {
				name: null
			},
			start: {
				date: new Date().toISOString(),
				time: new Date().toISOString()
			},
			end: {
				date: new Date().toISOString(),
				time: new Date().toISOString()
			},
			user: $auth.user(),
		}
	},
	created() {
		if (this.event) this.event = JSON.parse(JSON.stringify(this.event));
		if (this.event.start) this.start = {
			date: this.event.start,
			time: this.event.start
		};
		if (this.event.end) this.end = {
			date: this.event.end,
			time: this.event.end
		};
	},
	computed: {
		canPost() {
			return !!(this.event && this.event.name && this.event.name.trim());
		},
	},
	methods: {
		toggleCompany($event) {
			this.event.companyId = $event.target.checked ? this.user.company.id : null;
		},
		dismiss(data) {
			this.$ionic.modalController.dismiss(data);
		},
		publish() {
			this.event.id ? this.update() : this.create()
		},

		async create() {
			this.event.contactPersonId = this.user.id;
			const createProps = ['contactPersonId', 'name', 'start', 'end', 'picture', 'description', 'website', 'tags', 'companyId']
			const eventVariables = this.sanitizeEvent(createProps);

			const loadingController = await this.$ionic.loadingController.create({
				message: 'Please wait...'
			});
			loadingController.present();
			const res = await apolloClient.mutate({
				mutation: InsertEvent,
				variables: eventVariables,
			});
			loadingController.dismiss();
			this.dismiss(res.data.insert_Event.returning[0].id);
		},

		async update() {
			const updateProps = ['id', 'name', 'start', 'end', 'picture', 'description', 'website', 'tags', 'companyId']
			const eventVariables = this.sanitizeEvent(updateProps);

			const loadingController = await this.$ionic.loadingController.create({
				message: 'Please wait...'
			});
			loadingController.present();
			const event = await apolloClient.mutate({
				mutation: UpdateEvent,
				variables: eventVariables,
			});
			loadingController.dismiss();
			this.dismiss(event);
		},

		sanitizeEvent(props) {
			const eventVariables = JSON.parse(JSON.stringify(this.event));
			eventVariables.start = (this.start.date.split("T")[0]) + "T" + (this.start.time.split("T")[1]);
			eventVariables.end = (this.end.date.split("T")[0]) + "T" + (this.end.time.split("T")[1]);
			if (eventVariables.tags && eventVariables.tags.length > 0) {
				eventVariables.tags = '{' + eventVariables.tags.join(',') + '}'
			}
			Object.keys(eventVariables).forEach(v => {
				if (props.indexOf(v) < 0) {
					delete eventVariables[v];
				}
			});
			return eventVariables;
		}
	},
}
</script>

<style scoped>
.image-input {
	width: 100%;
}

.date-time {
	display: flex;
}

.date-time>* {
	flex: 1;
}
</style>
