<template>
    <ion-infinite-scroll id="infinite-scroll" @ionInfinite="$emit('fetchMore', $event)" threshold="25%" position="bottom">
        <ion-infinite-scroll-content loading-spinner="bubbles" loading-text="Loading more data...">
        </ion-infinite-scroll-content>
    </ion-infinite-scroll>
</template>

<script>
export default {
    name: 'infinite-scroll'
}
</script>