<template>
    <ion-card>
        <b>{{heading}}</b>
        <img class="company-logo" :src="company.picture" />
        <ion-card-title>{{company.name}}</ion-card-title>
        <ion-card-content>{{company.description}}</ion-card-content>
    </ion-card>
</template>

<script>
export default {
    props: {
        company: {
            type: Object,
            default: ''
        },
        heading: {
            type: String,
            default: 'Company Detail'
        }
    }
}
</script>

<style  scoped>
    .company-logo {
        margin-left: 20%;
        height: 200px;
        width: 200px;
    }
</style>