<template>
    <div>
        <comment-composer @addComment="addComment" :user="user" />

         <div class="comments" v-if="comments && comments.length">
            <ion-list-header>
                <ion-label><h4>Comments</h4></ion-label>
            </ion-list-header>
             <ion-list>
                <comment-card
                    v-for="comment in comments"
                    :key="comment.id"
                    :comment="comment"
                    @deleteComment="deleteComment"
                    @updateComment="updateComment"
                />
            </ion-list>
        </div>
    </div>
</template>

<script>
import $auth from "@/services/auth.js";
import getInsightDetail from '@/gql/insight/GetInsightDetail.gql';
import InsertComment from '@/gql/comment/InsertComment.gql';
import UpdateComment from '@/gql/comment/UpdateComment.gql';
import DeleteComment from '@/gql/comment/DeleteComment.gql';

import CommentComposer from './CommentComposer';
import CommentCard from './CommentCard';

export default {
    name: 'comments',

    components: {
        CommentComposer,
        CommentCard,
    },

    props: {
        comments: {
            type: Array,
            default: () => [],
        },

        sourceId: {
            type: String,
            required: true,
        },

        sourceType: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            user: $auth.user(),
            source: {
                'INSIGHT': {
                    data: 'insightDetail',
                    apollo_query: {
                        query: getInsightDetail,
                        variables: { id: this.sourceId }
                    },
                },
                'POST': { data: '', apollo_query: {} },
                'EVENT': { data: '', apollo_query: {} },
            },
        }
    },

    methods: {
        async addComment(content) {
            await this.$apollo.mutate({
                mutation: InsertComment,
                variables: {
                    insightId: (this.sourceType == 'INSIGHT') ? this.sourceId: null,
                    eventId: (this.sourceType == 'EVENT') ? this.sourceId: null,
                    postId: (this.sourceType == 'POST') ? this.sourceId: null,
                    sourceType: this.sourceType,
                    authorId: $auth.user().id,
                    content: content,
                },
                update: async (client, { data }) => {
                    const type = this.source[this.sourceType];

                    const queryData = await client.readQuery({ ...type.apollo_query });

                    queryData[type.data].comments.unshift(data.insert_Comment.returning[0]);

                    await client.writeQuery({
                        ...type.apollo_query,
                        data: { ...queryData }
                    });
                },
            }).catch((err) => {
                alert(err.message);
            });
        },

        async deleteComment(commentId) {
            let loadingController = await this.$ionic.loadingController.create({message: 'Please wait...' });
            loadingController.present();

            await this.$apollo.mutate({
                mutation: DeleteComment,
                variables: {
                    id: commentId,
                },
                update: async (client, { data }) => {
                    const type = this.source[this.sourceType];

                    const queryData = await client.readQuery({ ...type.apollo_query });

                    queryData[type.data].comments.splice(queryData[type.data].comments.findIndex(comment => comment.id === commentId), 1);

                    await client.writeQuery({
                        ...type.apollo_query,
                        data: { ...queryData }
                    });
                },
            });

            loadingController.dismiss();
        },

        async updateComment(comment) {
            await this.$apollo.mutate({
                mutation: UpdateComment,
                variables: { ...comment },
            });
        },
    },
}
</script>
