<template>
<div>
    <ion-header>
        <ion-toolbar>
            <ion-buttons slot="start">
                <ion-button @click="dismiss()">
                    <ion-icon slot="icon-only" name="close"></ion-icon>
                </ion-button>
            </ion-buttons>
            <ion-title>Share Something</ion-title>
            <ion-buttons slot="end">
                <ion-button @click="publish()" :disabled="!canPost || uploading">{{this.postId ? "Save" : "Post"}}</ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
        <ion-list>
        <ion-item>
            <ion-textarea auto-grow placeholder="Write something..." :value="message" @input="inputChanged" @ionBlur="checkMessageForLinks"></ion-textarea>
        </ion-item>
        <div>
            <ion-button @click="attachFile('image')" color="light">
                <ion-icon slot="icon-only" name="camera"></ion-icon>
            </ion-button>
            <ion-button @click="attachFile('video')" color="light">
                <ion-icon slot="icon-only" name="videocam"></ion-icon>
            </ion-button>
            <ion-button @click="attachFile('audio')" color="light">
                <ion-icon slot="icon-only" name="musical-notes"></ion-icon>
            </ion-button>
            <ion-button @click="attachFile('document')" color="light">
                <ion-icon slot="icon-only" name="document"></ion-icon>
            </ion-button>
            <ion-button @click="attachLink('link')" color="light">
                <ion-icon slot="icon-only" name="globe"></ion-icon>
            </ion-button>
        </div>
        <div v-if="uploading" class="ion-padding ion-text-center">
            <ion-spinner></ion-spinner>
        </div>
        <div v-if="!uploading && attachmentType">
            <ion-button color="danger" @click="removeAttachment">
                <ion-icon slot="icon-only" name="close"></ion-icon>
            </ion-button>
            <div v-if="attachmentType.includes('image')">
                <img :src="attachmentSrc">
            </div>
            <div v-else-if="attachmentType.includes('video')">
                <video :src="attachmentSrc"></video>
            </div>
            <div v-else-if="attachmentType.includes('audio')">
                <audio :src="attachmentSrc"></audio>
            </div>
            <div v-else-if="attachmentType === 'link'" @click.stop.capture>
                <link-preview :title="attachmentName" :link="attachmentLink" :description="attachmentDescription" :image="attachmentSrc"></link-preview>
            </div>
            <div v-else>
                <post-file :post="post"></post-file>
            </div>
        </div>
    </ion-list>
    </ion-content>
</div>
</template>

<script>
import InsertPost from '@/gql/feed/InsertPost.gql';
import UpdatePost from '@/gql/feed/UpdatePost.gql';
import GetLinkPreview from '@/gql/feed/GetLinkPreview.gql';
import $auth from '@/services/auth';
import $media from '@/services/media';
import {apolloClient} from '@/services/apollo';
import PostFile from '@/components/feed/PostFile.vue';
import LinkPreview from '@/components/feed/LinkPreview.vue';
const linkify = require('linkifyjs');

export default {
    name: 'PostComposer',
    components: {
        PostFile,
        LinkPreview,
    },
    data () {
        return {
            postId: null,
            type: 'STATUS',
            message: '',
            attachmentName: null,
            attachmentType: null,
            attachmentSrc: null,
            attachmentLink: null,
            attachmentDescription: null,
            previewLink: null,
            uploading: false,
        }
    },
    computed: {
        post () {
            return {
                type: this.type,
                message: this.message,
                attachmentName: this.attachmentName,
                attachmentType: this.attachmentType,
                attachmentSrc: this.attachmentSrc,
                attachmentLink: this.attachmentLink,
                attachmentDescription: this.attachmentDescription
            }
        },
        canPost() {
            return this.message.trim().length > 0 || this.attachmentSrc;
        },
    },
    methods: {
        dismiss () {
            this.$ionic.modalController.dismiss(false);
        },
        publish () {
            this.postId ? this.update() : this.create()
        },
        async create () {
            let loadingController = await this.$ionic.loadingController.create({message:'Please wait...'});
            loadingController.present();
            let post = await apolloClient.mutate({
                mutation: InsertPost,
                variables: {personId: $auth.user().id, ...this.post},
            });
            loadingController.dismiss();
            this.dismiss(true);
        },
        async update () {
            let loadingController = await this.$ionic.loadingController.create({message:'Please wait...'});
            loadingController.present();
            let post = await apolloClient.mutate({
                mutation: UpdatePost,
                variables: {id: this.postId, ...this.post},
            });
            loadingController.dismiss();
            this.dismiss(true);
        },
        async attachFile (type) {
            const file = await $media.selectFile(type);
            if(!file){
                // throw new Error('Please select at least one file');
                return false;
            }
            this.uploading = true;
            const attachment = await $media.uploadFile(file);
            this.attach({
                type: attachment.contentType,
                name: attachment.name,
                src: attachment.src,
            })
            this.uploading = false;
        },
        async attachLink () {
            const linkModal = await this.$ionic.alertController.create({
                header: "Attach Link",
                inputs: [{
                    placeholder: 'https://www.csuitecircle.com/',
                    type: 'url',
                }],
                backdropDismiss: false,
                buttons: [
                  {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                  },
                  {
                    text: 'Ok',
                    handler: (d) => {
                       const link = this.validateLink(d[0]);
                       if (link) this.linkPreview(link);
                    },
                  },
                ],
            });
            linkModal.present();
        },
        validateLink (link) {
            try {
                const url = new URL(link);
                return url.href;
            } catch (e) {
                return null;
            }
        },
        async linkPreview (link) {
            this.attachmentType = "link";
            this.uploading = true;
            const previewQuery = await apolloClient.query({
                query: GetLinkPreview,
                variables: {
                    url: link,
                }
            });
            this.uploading = false;

            const preview = previewQuery.data.LinkPreview;
            this.attach({
                type: "link",
                src: preview.image,
                name: preview.title,
                link: preview.link,
                description: preview.description
            });
        },
        attach (attachment) {
            const props = ['type', 'src', 'name', 'link', 'description']
            props.forEach(p => {
                this['attachment' + p[0].toUpperCase() + p.slice(1,p.length)] = attachment[p] || null;
            });
        },
        removeAttachment () {
            this.attach({});
        },
        inputChanged (e) {
            this.message = e.target.value;
            // Some checks to reduce frequency of searching for links
            // Don't search when typing unless the input was a space
            if (e.inputType === 'insertText' && e.data != ' ') return;
            // Don't search unless it's an "insert" type event
            // Full list of inputType can be found here: https://www.w3.org/TR/input-events-1/#interface-InputEvent-Attributes
            if (e.inputType.indexOf('insert') < 0) return;
            this.checkMessageForLinks();
        },
        checkMessageForLinks () {
            if (this.attachmentType) return;
            const links = linkify.find(this.message);
            if (links[0]) this.linkPreview(links[0].href);
        },
    },
}
</script>
<style scoped>
    video {
        width:100%;
    }
    ion-textarea {
        min-height:80px;
    }
</style>
