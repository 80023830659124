<template>
    <div>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                      <ion-button @click="dismiss()">
                          <ion-icon slot="icon-only" name="close"></ion-icon>
                      </ion-button>
                </ion-buttons>
                <ion-title>Request Inroduction</ion-title>
                <ion-buttons slot="end">
                    <ion-button @click="sendRequest" color="primary" :disabled="disableBtn">Send</ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
            <ion-item>
                <ion-label class="ion-text-wrap" position="floating">
                    Why would you like a warm introduction to {{ targetName }}?
                </ion-label>
                <ion-textarea :value="requestReason" @ionChange="requestReason = $event.target.value" required="true"></ion-textarea>
            </ion-item>
        </ion-content>
    </div>
</template>

<script>
export default {
    name: 'IntroductionRequestModal',

    data() {
        return {
            requestReason: '',
            targetName: '',
        }
    },

    computed: {
        disableBtn() {
            return this.requestReason && this.requestReason.trim().length < 1;
        }
    },

    methods: {
        sendRequest() {
            if(this.requestReason.length > 0) {
                this.$ionic.modalController.dismiss({ send: true, requestReason: this.requestReason });
            }
        },
        dismiss() {
            this.$ionic.modalController.dismiss(false);
        },
    }
}
</script>
