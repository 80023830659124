
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PostFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Post"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"person"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"picture"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"title"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"companyName"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"message"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"createdAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"attachmentName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"attachmentType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"attachmentSrc"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"attachmentLink"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"attachmentDescription"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"type"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":259}};
    doc.loc.source = {"body":"fragment PostFragment on Post {\n    id\n    person {\n      id\n      name\n      picture\n      title\n      companyName\n    }\n    message\n    createdAt\n    attachmentName\n    attachmentType\n    attachmentSrc\n    attachmentLink\n    attachmentDescription\n    type\n}","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
